$(document).ready(function(){
    let scrollChecker = function() {
            $(window).scroll(function() {
                let scroll = $(window).scrollTop() + $(window).height(),
                    $indicators = $('#carouselDashboard .carousel-indicators'),
                    $footer = $('footer'),
                    fTop = $footer.offset().top;

                if(scroll < fTop) {
                    $indicators.css({
                        'position' : 'fixed',
                        'bottom' : 0,
                        'top': 'auto'
                    });
                }
                if(scroll >= fTop) {
                    $indicators.css({
                        'position': 'absolute',
                        'top': fTop - $indicators.height() - $footer.height() - 16,
                        'bottom': 'auto'
                    });
                }
            });
        };

    $('#carouselDashboard').on('slide.bs.carousel', function () {
        scrollChecker();
    });

    scrollChecker();
});