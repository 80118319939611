
import '../css/app.scss';
import 'popper.js';
import 'bootstrap-datepicker';
import 'bootstrap';
import 'chart.js';
import 'clndr';
import moment from 'moment';
import { HomeOffice } from './home-office';


$(document).ready(function(){
    window.moment = require('moment-timezone');

    moment.tz.setDefault('Europe/Berlin');

    const isBackend = !!$('body.backend').length,
        hasZones = !!$('body[data-zones="1"]').length,
        hoDaysPerWeek = document.body.dataset.daysPerWeek ? JSON.parse(document.body.dataset.daysPerWeek) : null,
        hoAvailable = document.body.dataset.hoAvailable === '1' ? true : false;

    let timer;

    // catch ajax start
    $(document).ajaxStart(function(){
        timer = setTimeout(function() {
            $('body').append("<div class='preloader'><span class='content'><i class='fas fa-spinner-third'></i></span></div>");
        }, 250);
    });
    // catch ajax requests completed
    $(document).ajaxStop(function(){
        clearTimeout(timer);
        $(document).find('.preloader').fadeOut(250, function(){
            $(this).remove();
        });
    });


    $(document).ajaxError(function (event, jqXHR) {
        if (403 === jqXHR.status) {
            if(isBackend) {
                $(location).attr('href', '/backend/login');
            } else {
                $(location).attr('href', '/employee/login');
            }
        }
    });

    if (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch) {
        $('body').addClass('has-touch');
    } else {
        $('body').removeClass('has-touch');
    }


    $.fn.serializeObject = function()
    {
        var o = {},
            a = this.serializeArray();
        $.each(a, function() {
            if (o[this.name] !== undefined) {
                if (!o[this.name].push) {
                    o[this.name] = [o[this.name]];
                }
                o[this.name].push(this.value || '');
            } else {
                o[this.name] = this.value || '';
            }
        });
        return o;
    };

    let pomWhiteList = $.fn.tooltip.Constructor.Default.whiteList;
    pomWhiteList['a'].push('data-employee');
    pomWhiteList['a'].push('data-team');
    pomWhiteList['a'].push('data-id');
    pomWhiteList['*'].push('style');

    const calendarAjaxUrl = isBackend ? "/backend/calendar/" : "/employee/calendar/",
        $calendar = $('#calendar'),
        $employees = $('#employees'),
        thisEmployee = isBackend ? null : $calendar.data('employee'),
        $formErr = $('.form-error');
    let $calendarOverview = $('.schedule-calendar').not('.home-office'),
        startCheckZone = false,
        parkingOwner = isBackend ? false : $calendar.data('parkingOwner'),
        range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1}, (_, i) => start + (i * step)),
        clearForm = function(elem){
            $(elem).find(':input').each(function() {
                switch (this.type) {
                    case 'password':
                    case 'select-multiple':
                    case 'select-one':
                    case 'text':
                    case 'textarea':
                        $(this).val('');
                        break;
                    case 'checkbox':
                    case 'radio':
                        this.checked = false;
                }
            });
            $(elem).find('.form-error').empty();
        },

        getQueryString = function() {
            let key = false, res = {}, itm = null;
            // get the query string without the ?
            let qs = location.search.substring(1);
            // check for the key as an argument
            if (arguments.length > 0 && arguments[0].length > 1)
                key = arguments[0];
            // make a regex pattern to grab key/value
            let pattern = /([^&=]+)=([^&]*)/g;
            // loop the items in the query string, either
            // find a match to the argument, or build an object
            // with key/value pairs
            while (itm = pattern.exec(qs)) {
                if (key !== false && decodeURIComponent(itm[1]) === key)
                    return decodeURIComponent(itm[2]);
                else if (key === false)
                    res[decodeURIComponent(itm[1])] = decodeURIComponent(itm[2]);
            }

            return key === false ? res : null;
        },

        monthTitle = $('.month-title'),
        urlMonth = getQueryString('month'),
        month = monthTitle.length ? moment(monthTitle.data('month'), 'YYYY-MM-DD') : (urlMonth !== undefined && urlMonth !== null ? moment(urlMonth, 'YYYY-MM-DD') : moment()),

        getDaysArray = function(year, month) {
            const names = [ 'sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat' ];
            let date = new Date(year, month - 1, 1),
                result = [];
            while (date.getMonth() === month - 1) {
                result.push([date.getDate(), names[date.getDay()], moment(date).format('DD.MM.YYYY')]);
                date.setDate(date.getDate() + 1);
            }
            return result;
        },

        getDatesArr = function(start, end = null)
        {
            let dates = [];

            const startDate = moment(start, 'DD.MM.YYYY').startOf('day'),
                endDate = end !== null ? moment(end, 'DD.MM.YYYY').startOf('day') : startDate;

            dates.push(startDate.format('DD.MM.YYYY'));
            while(startDate.add(1, 'days').diff(endDate) < 0) {
                dates.push(startDate.clone().format('DD.MM.YYYY'));
            }
            if(end !== null) {
                dates.push(endDate.format('DD.MM.YYYY'));
            }

            return dates;
        },

        setBtnASSaving = function(btn, iconCls){
            let $icon = btn.find('.fal');
            $icon.removeClass(iconCls);
            $icon.addClass('fa-spinner');
            $icon.addClass('saving');
        },
        removeBtnSaving = function(btn, iconCls){
            let $icon = $('.fal.saving');
            $icon.removeClass('fa-spinner');
            $icon.addClass(iconCls);
            $icon.removeClass('saving');
        },

        togglePassword = function(elem, target) {
            if(!elem instanceof Element) {
                return;
            }
            const icon = target.classList.contains('fal') ? target : target.querySelector('.fal');
            if(elem.getAttribute('type') === 'password') {
                elem.setAttribute('type', 'text');
                icon.classList.remove('fa-eye');
                icon.classList.add('fa-eye-slash');
            } else if(elem.getAttribute('type') === 'text'){
                elem.setAttribute('type', 'password');
                icon.classList.remove('fa-eye-slash');
                icon.classList.add('fa-eye');
            }
        },

        getTogglePwdElements = function(parent = null){
            const container = parent === null ? $(document) : parent,
                pwdInputs = container.find('input[type="password"].toggle-password');
            if(pwdInputs.length > 0){
                pwdInputs.each((pwd, val) => {
                    const html = '<button type="button" tabindex="-1" class="btn btn-link position-absolute toggle-pwd" style="right:5px;bottom:0;"><i class="fal fa-eye"></i></button>';
                    val.closest('.form-group').classList.add('position-relative');
                    val.setAttribute('style', 'padding:.375rem 3rem .375rem .75rem');
                    val.insertAdjacentHTML('afterend', html);
                    val.closest('.form-group').querySelector('.toggle-pwd').addEventListener('click', function(e){
                        togglePassword(val, e.target);
                    });
                });
            }
        },

        successTrueMsg = function(message, success){
            let $body = $('body'),
                alertCls = success === true ? 'alert-success' : 'alert-danger';
            $body.prepend("<div class='success-message'>" +
                "<div class='inner-message alert " + alertCls + "'>" + message +
                "</div>" +
                "</div>");

            setTimeout(function() {
                $('.success-message').css({
                    top: '100px'
                });
            }, 250);

            setTimeout(function(){
                $('.success-message').animate({
                    top: '-200px'
                }, 250, function(){
                    $('.success-message').remove();
                });
            }, 5000);
        },

        cal,
        clickedDate = null,
        $attendanceSaveBtn = $("#attendance_save"),
        $attendanceNewForm = $(".attendance-form"),
        $chartWrapper = $("#chart-attendance"),
        $chartMonthWrapper = $("#chart-attendance-month"),
        $dashboardCalendar = $(".dashboard-calendar"),
        $employeeAttendanceSelect = $('.employee-attendance-select'),

        getArrayFrom = function(obj){
            let temp_array = [],
                length = obj.length;

            for (let i = 0; i < length; i++) {
                temp_array.push(obj[i]);
            }

            return temp_array;
        },

        // get month parameter from url
        getMonthFromURL = function(){
            let url = new URL(window.location.href);
            urlMonth = url.searchParams.get('month');
        },

        // open an bootstrap tooltip
        openTooltip = function(elem, title, mouseevent){
            if($('.tooltip').length > 0 && mouseevent !== true){
                $(elem).tooltip('hide');
                return true;
            }
            $(elem).tooltip({
                title: title,
                trigger: 'manual'
            }).tooltip('show');
        },
        // close tooltip
        closeTooltip = function(elem = null){
            if(elem === null) {
                $('.tooltip').tooltip('hide');
            }
            $(elem).tooltip('hide');
        },

        changeExportURL = function() {

            const $header = $('.calendar-header');

            if(!$header.hasClass('attendance-export')) {
                return false;
            }

            const $exportBtn = $('.attendance-export-btn'),
                url = $exportBtn.attr('href'),
                date = $header.find('.month-title').data('month'),
                team = $header.find('.team-filtered').data('team'),
                zone = $header.find('.zone-filtered').data('zone');

            let href = new URL(url);
            if(team !== undefined && team !== null) {
                href.searchParams.set('team', team);
            } else {
                href.searchParams.delete('team');
            }
            if(zone !== undefined && zone !== null) {
                href.searchParams.set('zone', zone);
            } else {
                href.searchParams.delete('zone');
            }
            href.searchParams.set('date', date);
            $exportBtn.attr('href', href);
        },

        getMyTeams = function() {
            if(isBackend) {
                return true;
            }
            let teamsHtml = "";
            $.ajax({
                url: '/employee/account/teams/getMyTeams',
                type: 'post',
                dataType: 'json'
            }).done(function(data){
                if(data['success'] === false) {
                    return false;
                }
                const $month = $(document).find('.month-title'),
                    fragment = window.location.hash.substring(1);
                let teamsHtml = "",
                    activeCls = '',
                    team;
                if(fragment.length) {
                    const splits = fragment.split("=");
                    team = parseInt(splits[1]);
                }

                $.each(data['data'], function(k, v){
                    if(v['id'] === team) {
                        activeCls = ' active';
                    }
                    teamsHtml += "<a href='#' class='dropdown-item team-filter' data-id='" + v['id'] + "'>" + v['title'] + "</a>";
                });

                $month.after("<div class='dropdown dropdown-team-filter d-inline-block ml-2'><button class='btn btn-outline-primary btn-sm dropdown-toggle" + activeCls + "' type='button' id='myTeamsBtn' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'><i class='fal fa-filter'></i> My Teams</button><div class='dropdown-menu'>" + teamsHtml + "</div></div>");
            });

        },


        filterTeamEmployeeCalendar = function(team, $target) {
            const $calendar = $('.schedule-calendar').not('.home-office'),
                $results = $('.calendar-filter-results');

            if(!$target.length) {
                return false;
            }

            if($results.length) {
                $results.remove();
            }

            $calendar.find('.calendar-header .dropdown > button').removeClass('active');

            $target.removeClass('active');
            $.ajax({
                url: '/employee/account/teams/getTeam',
                type: 'post',
                dataType: 'json',
                async: true,
                data: {id: team}
            }).done(function(data) {
                if (data['success'] === false) {
                    return false;
                }
                window.location.hash = '#team=' + team;

                $target.addClass('active');

                $target.closest('.dropdown').after("<div class='calendar-filter-results teams d-inline-block ml-2'><a href='#' class='badge-link calendar-cancel-filter' data-filter='#" + $target.attr('id') + "'><span class='badge badge-secondary team-filtered' data-team='" + team + "'>" + data['data']['title'] + " <i class='fas fa-times-circle'></i></a></span></div>");

                $calendar.find('.employee').removeClass('d-flex').addClass('d-none');
                $calendar.find('.employee-calendar').addClass('d-none');

                $.each(data['data']['employees'], function(k,v){
                    const $employee = $('.schedule-calendar:not(.home-office) .employee-wrapper .employee[data-id="' + v + '"]'),
                        $employeeCal = $('.calendar .employee-calendar[data-id="' + v + '"]');
                    $employee.removeClass('d-none').addClass('d-flex');
                    $employeeCal.removeClass('d-none');
                });

                changeExportURL();

            });
        },


        getZones = function() {
            if(isBackend || !hasZones) {
                return true;
            }

            let teamsHtml = "";
            $.ajax({
                url: '/employee/calendar-overview/zone/get-zones',
                type: 'post',
                dataType: 'json'
            }).done(function(data){
                if(data['success'] === false) {
                    return false;
                }
                const $month = $(document).find('.month-title'),
                    fragment = window.location.hash.substring(1);
                let zonesHtml = "",
                    activeCls = '',
                    zone;
                if(fragment.length) {
                    const splits = fragment.split("=");
                    zone = parseInt(splits[1]);
                }

                if(data['data'].length === 0) {
                    return false;
                }

                $.each(data['data'], function(k, v){
                    if(v['id'] === zone) {
                        activeCls = ' active';
                    }
                    zonesHtml += "<a href='#' class='dropdown-item zone-filter' data-id='" + v['id'] + "'>" + v['title'] + "</a>";
                });

                $month.after("<div class='dropdown dropdown-zone-filter d-inline-block ml-2'><button class='btn btn-outline-primary btn-sm dropdown-toggle" + activeCls + "' type='button' id='myZonesBtn' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'><i class='fal fa-filter'></i> Zones</button><div class='dropdown-menu'>" + zonesHtml + "</div></div>");
            });
        },


        filterZoneEmployeeCalendar = function(zone, $target) {

            if(!hasZones) {
                return false;
            }

            const $calendar = $('.schedule-calendar').not('.home-office'),
                $results = $('.calendar-filter-results');

            if(!$target.length) {
                return false;
            }

            if($results.length) {
                $results.remove();
            }

            $calendar.find('.calendar-header .dropdown > button').removeClass('active');

            $target.removeClass('active');
            $.ajax({
                url: '/employee/calendar-overview/zone/get-zone',
                type: 'post',
                dataType: 'json',
                async: true,
                data: {id: zone}
            }).done(function(data) {
                if (data['success'] === false) {
                    return false;
                }
                window.location.hash = '#zone=' + zone;

                $target.addClass('active');

                $target.closest('.dropdown').after("<div class='calendar-filter-results zones d-inline-block ml-2'><a href='#' class='badge-link calendar-cancel-filter' data-filter='#" + $target.attr('id') + "'><span class='badge badge-secondary zone-filtered' data-zone='" + zone + "'>" + data['data']['title'] + " <i class='fas fa-times-circle'></i></a></span></div>");

                $calendar.find('.employee').removeClass('d-flex').addClass('d-none');
                $calendar.find('.employee-calendar').addClass('d-none');

                $.each(data['data']['employees'], function(k,v){
                    const $employee = $('.schedule-calendar:not(.home-office) .employee-wrapper .employee[data-id="' + v + '"]'),
                        $employeeCal = $('.calendar .employee-calendar[data-id="' + v + '"]');
                    $employee.removeClass('d-none').addClass('d-flex');
                    $employeeCal.removeClass('d-none');
                });

                changeExportURL();
            });
        },

        weekNum = function (dt)
        {
            const tdt = new Date(dt.valueOf());
            let dayn = (dt.getDay() + 6) % 7;
            tdt.setDate(tdt.getDate() - dayn + 3);
            const firstThursday = tdt.valueOf();
            tdt.setMonth(0, 1);
            if (tdt.getDay() !== 4) {
                tdt.setMonth(0, 1 + ((4 - tdt.getDay()) + 7) % 7);
            }
            return 1 + Math.ceil((firstThursday - tdt) / 604800000);
        },


        // get the calendar via clndr plugin
        // events includes all events
        // month define the month with the calendar starts
        getCalendar = function (events, month, employee, weeks) {

            let cal = $calendar.clndr({
                template: $("#template-calendar").html(),
                startWithMonth: month,
                weekOffset: 1,
                daysOfTheWeek: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
                multiDayEvents: {
                    endDate: 'end',
                    startDate: 'start',
                    singleDay: 'date'
                },
                /*constraints: {
                    startDate: moment().subtract('2', 'weeks').subtract('1', 'day')
                },*/
                extras: {
                    options: {
                        show_weekends: false,
                        constraintStartDate: moment().subtract('1', 'weeks').subtract('1', 'day'),
                        weekNumbers: weeks,
                    },
                },
                ignoreInactiveDaysInSelection: true,
                clickEvents: {

                    onMonthChange: function(month) {

                        const date = month;

                        let $employees = $('#employees'),
                            employee = $employees.val();

                        getCalendarEvents(employee, date);
                    },

                    click: function (target) {

                        let $employees = $('#employees'),
                            employee = $employees.val(),
                            represented = $employees.find('option:selected').data('represented');

                        clickedDate = moment(target.date._i).format('DD.MM.YYYY');

                        if ((parseInt(employee) === parseInt(thisEmployee) || parseInt(represented) === parseInt(thisEmployee)) && !$(target.element).hasClass('inactive') && !$(target.element).hasClass('adjacent-month') && !$(target.element).hasClass('is-holiday')) {
                            if ($(target.element).hasClass('event') && !$(target.element).hasClass('is-zone')) {
                                openModal('info', target.element, moment(target.date._i).format('DD.MM.YYYY'));
                            } else {
                                openModal('new', target.element, moment(target.date._i).format('DD.MM.YYYY'));
                            }
                        }
                    }
                },
                doneRendering: function() {
                    let self = this;
                    let $employees = $('#employees'),
                        employee = $employees.val(),
                        represented = $employees.find('option:selected').data('represented');

                    $(document).on('mouseover touchstart', '.has-comment, .is-holiday', function (e) {
                        const target = self.buildTargetObject(e.currentTarget, true),
                            comment = target !== undefined && target.events.length > 0 ? target.events[0].comment : undefined;
                        if (comment !== undefined && comment.length > 0 && ((parseInt(employee) !== parseInt(thisEmployee) && parseInt(represented) !== parseInt(thisEmployee)) || ((parseInt(employee) === parseInt(thisEmployee) && parseInt(represented) !== parseInt(thisEmployee)) && $('body').hasClass('has-touch') === false))) {
                            openTooltip(target.element, comment, true);
                        }
                    });

                    $(document).on('mouseleave touchend', '.has-comment, .is-holiday', function (e) {
                        let target = self.buildTargetObject(e.currentTarget, true);
                        closeTooltip(target.element);
                    });
                },
                showAdjacentMonths: false
            });
            cal.setEvents(events);
        },

        getCalendarEvents = function(employee, paramMonth = null){
            const calendarEvents = [];
            urlMonth = getQueryString('month');
            let month = urlMonth !== undefined && urlMonth !== null ? moment(urlMonth, 'YYYY-MM-DD') : (paramMonth !== undefined && paramMonth !== null ? moment(paramMonth, 'YYYY-MM-DD') : moment()),
                ajaxUrl = isBackend ? '/backend/' : '/employee/';

            closeTooltip();

            $.ajax({
                method: "post",
                dataType: "json",
                async: true,
                url: '/employee/calendar/get',
                data: {employee: employee, month: month.format('DD.MM.YYYY')},
                success: function (data) {

                    if (data['success'] === false) {
                        return false;
                    }

                    $.each(data, function (key, event) {
                        let item;
                        if(key === 'holidays') {
                            $.each(event, function(k, holiday){
                                item = {
                                    "date": holiday.date,
                                    "id": holiday.id,
                                    "title": holiday.name,
                                    "comment": holiday.name,
                                    "color": "#f0f0f0",
                                    "isHoliday": holiday.isHoliday
                                };
                                calendarEvents.push(item);
                            });
                            return true;
                        }

                        if(key === 'zones') {
                            $.each(event, function(k, v){
                                const date = k;
                                $.each(v, function(zKey, zone){
                                    item = {
                                        "date": date,
                                        "title": zone.zone,
                                        "isZone": true,
                                        "percent": zone.percent,
                                        "percent_max": zone.limit_percent,
                                        "present": zone.present,
                                        "present_max": zone.limit_present,
                                        "color": '#fff'
                                    };
                                });
                                calendarEvents.push(item);
                            });
                            return true;
                        }

                        if (event.end === undefined || event.end === null || event.end === '') {
                            item = {
                                "date": event.start,
                                "title": event.attendanceType,
                                "color": event.color,
                                "id": event.id,
                                "comment": event.comment,
                                "parkingLotFree": event.parkingLotFree,
                                "isPresent": event.isPresent,
                                "zoneTitle": event.zoneTitle,
                                "homeOffice": event.homeOffice,
                                "homeOfficeStatus": event.homeOfficeStatus,
                                "homeOfficePast": event.homeOfficePast,
                            };
                        } else {
                            item = {
                                "start": event.start,
                                "end": event.end,
                                "title": event.attendanceType,
                                "color": event.color,
                                "id": event.id,
                                "comment": event.comment,
                                "parkingLotFree": event.parkingLotFree,
                                "isPresent": event.isPresent,
                                "zoneTitle": event.zoneTitle,
                                "homeOffice": event.homeOffice,
                                "homeOfficeStatus": event.homeOfficeStatus,
                                "homeOfficePast": event.homeOfficePast,
                            };
                        }
                        if(event.zones !== undefined && event.zones !== null && event.zones.length) {
                            item.zones = event.zones;
                        }
                        calendarEvents.push(item);
                    });

                    const dateObj = month.toDate(),
                        firstDay = new Date(dateObj.getFullYear(), dateObj.getMonth(), 1),
                        lastDay = new Date(dateObj.getFullYear(), dateObj.getMonth() + 1, 0),
                        firstDayWeek = weekNum(firstDay),
                        lastDayWeek = weekNum(lastDay),
                        weeks = range(firstDayWeek, lastDayWeek, 1);

                    $calendar.clndr().destroy();
                    getCalendar(calendarEvents, month, employee, weeks);
                },
                error: function (jqXHR, textStatus, errorThrown) {
                }
            });
        },

        getEmployeeCalendarHeader = function(month){
            let $wrapper = $calendarOverview.find('.calendar-wrapper'),
                $header = $wrapper.find('.calendar-header'),
                monthTxt = moment(month).format('MMMM YYYY'),
                html = "<strong class='month-title btn btn-outline-primary btn-sm ml-2 mr-3' data-month='" + moment(month).format('YYYY-MM-01') + "'>" + monthTxt + "</strong>",
                days = getDaysArray(moment(month).format('YYYY'), moment(month).format('MM'));
            const backendCls = isBackend ? ' is-backend' : '',
                hasReportPermission = !!$('body').hasClass('has-day-report'),
                ajaxUrl = isBackend ? '/backend/calendar/' : '/employee/calendar-overview/';

            $.ajax({
                url: ajaxUrl + 'first-aider-days',
                type: 'post',
                dataType: 'json',
                data: {month: moment(month).format('DD.MM.YYYY')}
            }).done(function(data){
                html += "<div class='days d-flex justify-content-start'>";
                const exportClass = !isBackend ? "" : (hasReportPermission ? " export-day" : "");
                $.each(days, function(k, day){
                    let hasNoFirstAider = ' no-first-aider';
                    const dayDate = moment(day + "." + moment(month).format('MM.YYYY'), 'D.MM.YYYY'),
                        isWeekend = dayDate.format('dddd') === 'Sunday' || dayDate.format('dddd') === 'Saturday',
                        weekendCls = isWeekend ? ' weekend' : '';
                    $.each(data['data'], function(key, item) {
                        const startDate = moment(item.start, 'DD.MM.YYYY'),
                            endDate = moment(item.end, 'DD.MM.YYYY');
                        if(dayDate.diff(startDate, 'days') === 0 || dayDate.isBetween(startDate, endDate, 'days', '[]')) {
                            hasNoFirstAider = '';
                        }
                    });

                    html += "<div class='day d-flex align-items-center justify-content-center" + hasNoFirstAider + weekendCls + exportClass + "' data-day='" + day[0] + "'><span>" + day[0] + "</span></div>";
                });
                getMyTeams();

                getZones();

                html += "</div>";
                $header.html(html);

                if(!isBackend && $header.hasClass('attendance-export')) {
                    $header.find('.month-title').after("<a href='" + window.location.origin + "/employee/calendar-overview/export' class='btn btn-outline-primary btn-sm attendance-export-btn ml-2'><i class='fal fa-download'></i> Export</a>");
                    changeExportURL();
                }
            });
        },
        getEmployeeCalendar = function(year, month) {
            let calHtml = "",
                $wrapper = $calendarOverview.find('.calendar-wrapper'),
                $monthTitle = $calendarOverview.find('.month-title');
            const backendCls = isBackend ? ' is-backend' : '',
                ajaxUrl = isBackend ? '/backend/calendar/' : '/employee/calendar-overview/';

            $.ajax({
                url: ajaxUrl + 'get_by_employee',
                type: 'post',
                dataType: 'json',
                data: {year: year, month: month}
            }).done(function(data){
                if(data['success'] === false){
                    calHtml += "<div class='alert alert-danger'>Loading calendar failed.</div>";
                    $wrapper.find('.calendar').html(calHtml);
                    return false;
                }

                const allHolidays = data['holidays'];

                $.each(data['data'], function(k, employee){
                    let employeeHtml = "";
                    employeeHtml += "<div class='employee-days d-flex justify-content-start'>";
                    const $employeeCal = $('.employee-calendar[data-id="' + k + '"]'),
                        today = moment().startOf('day'),
                        attendances = employee['attendances'];
                    let daysInMonth = moment(year + '' + month + '01', 'YYYYMMDD').daysInMonth();

                    for(let d = 1; d <= daysInMonth; d++) {
                        let bgColor = '#fff',
                            icon = '',
                            tooltipCls = '',
                            commentTxt = '',
                            tooltip = '',
                            thisDate = moment(d + "." + month + "." + year, 'DD.MM.YYYY').startOf('day'),
                            thisDay = thisDate.day(),
                            todayCls = thisDate.diff(today, 'days') === 0 ? ' today' : '',
                            weekendCls = thisDay === 0 || thisDay === 6 ? ' weekend' : '',
                            id = '',
                            employeeId = '',
                            dataAttr = '',
                            hasAtt = false,
                            zone = null,
                            zoneTxt = '',
                            homeOffice = null,
                            isHoliday = false;

                        if(allHolidays[employee['location']] !== undefined && allHolidays[employee['location']][thisDate.format('DD.MM.YYYY')] !== undefined) {
                            isHoliday = true;
                            bgColor = "#f5f5f5";
                            icon = "<i class='fal fa-star'></i>";
                            tooltipCls = ' is-holiday';
                            tooltip = " date-comment='" + allHolidays[employee['location']][thisDate.format('DD.MM.YYYY')] + "'";
                        }

                        $.each(attendances, function(key, att) {

                            const attStart = moment(att['startDate']['date']),
                                attEnd = att['endDate'] !== undefined && att['endDate'] !== null ? moment(att['endDate']['date']) : null;

                            if((attEnd === null && thisDate.diff(attStart, 'days') === 0) || (attEnd !== null && thisDate.isBetween(attStart, attEnd, 'days', '[]'))) {
                                hasAtt = true;
                                if(weekendCls === '' && !isHoliday){
                                    zone = att['zone_title'];
                                    zoneTxt = zone !== null && zone !== undefined ? 'Zone: ' + zone : '';
                                    commentTxt = att['comment'] !== null ? att['comment'] : '';
                                    bgColor = att['color'];
                                    icon = "<i class='" + att['icon'] + "'></i>";
                                    tooltipCls = (att['comment'] !== null && att['comment'] !== '') || (zone !== null && zone !== undefined) ? ' has-comment' : '';
                                    tooltip = " data-comment='" + commentTxt + "\n" + zoneTxt + "'";
                                    id = att['id'] === undefined ? null : att['id'];
                                    employeeId = att['employeeId'] === undefined ? '' : att['employeeId'];

                                    if(att['homeOffice']) {
                                        const ho = att['homeOffice'];
                                        switch(ho.status) {
                                            // ho was rejected
                                            case 0:
                                                icon = "<i class='fal fa-briefcase'></i>";
                                                bgColor = "#a7c4de";
                                                break;
                                            // ho was approved
                                            case 1:
                                                icon = "<i class='fal fa-house-user'></i>";
                                                break;
                                            // ho unanswered
                                            case 2:
                                                icon = "<i class='fal fa-briefcase'></i>";
                                                bgColor = "#a7c4de";
                                                break;
                                            // ho will deleted
                                            case -1:
                                                break;
                                            // ho was requested
                                            default:
                                                break;
                                        }
                                        if(ho.status === 0) {
                                            
                                        }
                                    }

                                }

                                if(isBackend && !isHoliday) {
                                    dataAttr += !id ? '' : " data-attendance='" + id + "'";
                                }
                                employeeHtml += "<div class='day d-flex align-items-center justify-content-center grey" + todayCls + weekendCls + tooltipCls + backendCls + "' style='background-color: " + bgColor + ";'" + tooltip + " data-date='" + thisDate.format('DD.MM.YYYY') + "'" + dataAttr + ">" + icon + "</div>";
                                return true;
                            }
                        });
                        if(!hasAtt) {
                            employeeHtml += "<div class='day d-flex align-items-center justify-content-center grey" + todayCls + weekendCls + tooltipCls + backendCls + "' style='background-color: " + bgColor + ";'" + tooltip + " data-date='" + thisDate.format('DD.MM.YYYY') + "'" + dataAttr + ">" + icon + "</div>";
                        }
                    }
                    employeeHtml += "</div>";
                    $employeeCal.html(employeeHtml);
                });

                // if team filter is active
                // load team members
                const fragment = window.location.hash.substring(1);
                let team;
                if(fragment.length) {
                    const splits = fragment.split("=");
                    team = parseInt(splits[1]);
                }
                if(team > 0) {
                    const $filterBtn = $('.dropdown-team-filter').find('.dropdown-toggle');
                    if ($filterBtn.length) {
                        filterTeamEmployeeCalendar(team, $filterBtn);
                    }
                }
            });
        },

        checkConflicts = function(id, start, end, employee){
            if(moment(start, 'DD.MM.YYYY', true).isValid()){
                if(end === undefined || end === null || end === '' || !moment(end, 'DD.MM.YYYY', true).isValid()){
                    end = null;
                }
                $.ajax({
                    url: '/employee/calendar/getconflicts',
                    type: 'post',
                    dataType: 'json',
                    data: {id: id, start: start, end: end, employee: employee}
                }).done(function(data){
                    let count = Object.keys(data).length,
                        $conflicts = $(".conflicts");

                    if($conflicts.length > 0){
                        $conflicts.remove();
                    }

                    if(count > 0){
                        let $modal = $(".modal.show"),
                            $form = $modal.find('form'),
                            $lastRow = $form.find('.form-row').last(),
                            html = '',
                            info = '',
                            delItems = '',
                            editItems = '';

                        info = "We detect " + count + " conflict. The following actions are performed when you save the entry.";

                        html = "<div class='row conflicts calendar-legend'><div class='col-12'><p><strong>Caution</strong></p></div>" +
                            "<div class='col-12'><p>" + info + "</p></div>" +
                            "<div class='col-12'><ul class='w-100 gray-dark'>";

                        $.each(data, function(k, i){
                            let date = '',
                                endDate = null;
                            if(i['endDate'] !== null && i['endDate'] !== undefined && i['endDate']['date'] !== '' && moment(i['endDate']['date'], 'YYYY-MM-DD').isValid()){
                                date = " – " + moment(i['endDate']['date']).format('DD.MM.YYYY');
                                endDate = moment(i['endDate']['date']).format('DD.MM.YYYY');
                            }

                            const dates = getDatesArr(moment(i['startDate']['date']).format('DD.MM.YYYY'), endDate);

                            // If conflict must deleted
                            if(i['delete'] === true){
                                delItems += "<li><span class='legend-color' style='width: 100%; background-color: " + i['color'] + ";'><i class='" + i['icon'] + "'></i> " + i['title'] + ": " + moment(i['startDate']['date']).format('DD.MM.YYYY') + date + "</span></li>" +
                                    "<input type='hidden' name='conflicts[]' value='" + i['id'] + "'><input type='hidden' name='conflictDates[" + i['id'] + "]' value='" + JSON.stringify(dates) + "'>";
                            } else {
                                editItems += "<li><span class='legend-color' style='width: 100%; background-color: " + i['color'] + ";'><i class='" + i['icon'] + "'></i> " + i['title'] + ": " + moment(i['startDate']['date']).format('DD.MM.YYYY') + date + "</span></li>" +
                                    "<input type='hidden' name='conflicts[]' value='" + i['id'] + "'><input type='hidden' name='conflictDates[" + i['id'] + "]' value='" + JSON.stringify(dates) + "'>";
                            }
                        });

                        if(delItems.length > 0){
                            html += "<li><span class='text-danger'>These entries are <strong>deleted</strong></span></li>";
                            html += delItems;
                        }
                        if(editItems.length > 0){
                            html += "<li><span class='text-warning'>These entries are <strong>edited</strong></span></li>";
                            html += editItems;
                        }
                        html += "</ul></div></div>";

                        $lastRow.before(html);
                    }
                });
            }
        },

        checkReservations = function(start, end, isModal, empl = null) {
            if(parkingOwner === true) {
                return true;
            }
            const $elem = $('.reservations-info');
            if($elem.length) {
                $elem.remove();
            }
            if(moment(start, 'DD.MM.YYYY', true).isValid()){
                const ajaxUrl = isBackend ? '/backend/parking/' : '/parking/';
                $.ajax({
                    url: ajaxUrl + 'get-reservations-by-period',
                    type: 'post',
                    dataType: 'json',
                    async: true,
                    data: {startDate: start, endDate: end, employee: empl}
                }).done(function(data) {

                    if(data['success'] === false || data['data'] === false || data['data'].length === 0) {
                        return true;
                    }

                    let count = Object.keys(data['data']).length;

                    if(count > 0) {

                        let $modal = $(".modal.show"),
                            $form = isModal ? $modal.find('.modal-body') : $modal.find('form'),
                            i = 0,
                            inputs = "",
                            $lastRow = isModal ? $form.find('.modal-actions p').last() : $form.find('.form-row').last(),
                            html = "";

                        if(isModal) {
                            html = "<hr class='mt-3'><p><strong><i class='fal fa-parking'></i> Parking</strong><br>We found parking reservations for the following days. </p><p>";
                            $.each(data['data'], function (k, v) {
                                i++;
                                html += "<b>" + moment(k, 'YYYY-MM-DD').format('DD.MM.YYYY') + "</b>";
                                $.each(v, function(key,value){
                                    inputs += "<input type='hidden' class='reservations' name='reservations[]' value='" + value['id'] + "'>";
                                });
                                if (i < count) {
                                    html += ", ";
                                }
                            });

                            html += "</p><p>Do you want to delete parking reservations, too? Your guest reservations will not be deleted.<br><div class='form-group form-check'><input type='checkbox' name='delete-reservations' value='1' id='delete-reservations' class='form-check-input'> <label for='delete-reservations' class='form-check-label'>Yes, delete parking reservations</label></div></p>"

                            html += inputs + "<hr class='mb-3'>";
                        } else {
                            html = "<div class='row conflicts calendar-legend'><div class='col-12'><p><strong><i class='fal fa-parking'></i> Parking</strong></p></div><div class='col-12'><p>We found parking reservations for the following days. If you are not present or you request mobile work on these days we will delete the parking reservations for you automatically.<br>Your guest reservations will not be deleted.</p><p>";

                            $.each(data['data'], function (k, v) {
                                i++;
                                html += "<b>" + moment(k, 'YYYY-MM-DD').format('DD.MM.YYYY') + "</b>";
                                $.each(v, function(key,value){
                                    inputs += "<input type='hidden' name='reservations[]' value='" + value['id'] + "'>";
                                });
                                if (i < count) {
                                    html += ", ";
                                }
                            });

                            html += "</p>" + inputs + "</div></div>";
                        }

                        $lastRow.before(html);
                    }
                });
            }
        },

        getAvailableZones = function(employee, $parent) {

            const $conflicts = $parent.find('.zone-conflict');
            $conflicts.each(function(k,v){
                const $this = $(this),
                    date = $this.data('date');

                let zonesFreeSelect = '';
                $.post('/employee/attendance/get-available-zones/' + employee, {date: date})
                    .done(function(data){
                        if(data['success'] === false) {
                            return false;
                        }

                        if(data['data'].length) {
                            zonesFreeSelect += "<select name='zones_free[" + date + "]' class='custom-select' data-date='" + date + "'>" +
                                "<option selected='selected' value=''>No zone</option>";

                            $.each(data['data'], function(k,zone){
                                zonesFreeSelect += "<option value='" + zone['id'] + "'>" + zone['title'] + "</option>";
                            });
                            zonesFreeSelect += "</select>";
                        }

                        $this.find('.zones-free').html(zonesFreeSelect);

                    });
            });

        },

        checkZone = function(start, end, employee, type = null) {

            if(!hasZones) {
                return true;
            }

            const $modal = $(".modal.show"),
                $form = $modal.find('form'),
                $conflicts = $modal.find('.zone-conflicts');

            if($conflicts.length) {
                $conflicts.remove();
            }

            if(type === null || type === 0) {
                return true;
            }

            if(startCheckZone) {
                return true;
            }

            startCheckZone = true;

            $.post('/employee/calendar/is-present', {type: type})
                .done(function(data){
                    startCheckZone = false;
                    if(data['success' === false]) {
                        return false;
                    }

                    if(data['data']) {

                        if(moment(start, 'DD.MM.YYYY', true).isValid()) {
                            if (end === undefined || end === null || end === '' || !moment(end, 'DD.MM.YYYY', true).isValid()) {
                                end = null;
                            }

                            $.post('/employee/attendance/check-zone/' + employee, {start: start, end: end})
                                .done(function(data){
                                    if(data['success'] === false) {
                                        return false;
                                    }

                                    const $lastRow = $form.find('.form-row').last();
                                    let conflict_days = [],
                                        html = '',
                                        conflict_list = '',
                                        badge_percent_cls = '',
                                        badge_present_cls = '',
                                        i = 0,
                                        has_conflicts = false;

                                    $.each(data['data'], function(k,v){
                                        const date = k,
                                            date_format = moment(date, 'YYYY-MM-DD').format('DD.MM.YYYY');

                                        $.each(v, function(zk, zone)
                                        {
                                            if (zone['available'] === true) {
                                                return false;
                                            }

                                            has_conflicts = true;
                                            conflict_days.push(date_format);

                                            if(i === 0) {
                                                conflict_list += "<p><strong>Zone " + zone['zone'] + " in " + zone['location'] + "</strong></p>";
                                            }

                                            if(parseInt(zone['percent']) < parseInt(zone['limit_percent'])) {
                                                badge_percent_cls = 'badge-success';
                                            } else {
                                                badge_percent_cls = 'badge-danger';
                                            }

                                            if(parseInt(zone['present']) < parseInt(zone['limit_present'])) {
                                                badge_present_cls = 'badge-success';
                                            } else {
                                                badge_present_cls = 'badge-danger';
                                            }

                                            conflict_list += "<tr class='zone-conflict' data-date='" + date + "'><td><strong>" + date_format + "</strong></td><td><span class='badge-group'><span class='badge " + badge_percent_cls + " badge-calendar px-2'><i class='fas fa-percentage'></i> " + zone['percent'] + " of " + zone['limit_percent'] + "</span><span class='badge " + badge_present_cls + " badge-calendar px-2'><i class='fas fa-users'></i> " + zone['present'] + " of " + zone['limit_present'] + "</span></span></td><td class='zones-free'> </td></tr>";

                                            i++;

                                        });

                                    });

                                    if(has_conflicts) {
                                        let info = "We detected zone conflicts. The following days are reached the defined limit. For these days you can define a alternative zone. The zone-responsibles will be informed about your selections.";

                                        html = "<div class='row zone-conflicts calendar-legend'>" +
                                            "<div class='col-12'><p>" + info + "</p></div>" +
                                            "<div class='col-12'><table class='table w-100 gray-dark'><tbody>";
                                        html += conflict_list;
                                        html += "</tbody></table>";
                                        html += "<input type='hidden' name='conflict_days' value='" + JSON.stringify(conflict_days) + "'>";
                                        html += "</div>";
                                    }

                                    $lastRow.before(html);
                                    getAvailableZones(employee, $('.zone-conflicts').find('table'));
                                });

                        }

                    }

                });

        },

        getHoLimitDate = function(limitHour = 16) {
            const now = moment();
            return now.hour() >= limitHour ? now.add('1', 'day').hour(limitHour) : now;
        },

        openModal = function(type, elem, date, empl = null){

            let $this,
                $elem = $(elem);

            if(isBackend) {
                $elem = $elem.hasClass('fa') || $elem.hasClass('fal') ? $elem.closest('.day.is-backend') : $elem;
            }

            $this = $elem.data("attendance") === undefined || $elem.data("attendance") === 0 ? $("#attendanceNew") : $("#attendanceInfo");

            if(type === 'edit'){
                $this = $("#attendanceEdit");
                // if home office is not available
                // remove home office option from DOM
                const startDateField = $('#attendanceEdit #attendance_startDate'),
                        endDateField = $('#attendanceEdit #attendance_endDate'),
                        hoType = document.querySelector('#attendanceEdit #attendance_attendanceTypeId input[value="3"]');
                if(hoAvailable === false) {
                    hoType !== null ? (hoType.checked ? hoType.disabled = true : hoType.closest('.form-check').remove()) : null;
                    startDateField.prop('disabled', true);
                    endDateField.closest('.form-group').addClass('d-none');
                    endDateField.prop('disabled', true);
                } else {
                    const startDate = moment(startDateField.val(), 'DD.MM.YYYY');
                    if(hoType.checked) {
                        endDateField.closest('.form-group').addClass('d-none');
                    }
                    if(startDate.isSameOrBefore(getHoLimitDate())) {
                        hoType.closest('.form-check').classList.add('d-none');
                        hoType.disabled = true;
                        if(hoType.checked) {
                            startDateField.prop('disabled', true);
                            hoType.disabled = true;
                            hoType.closest('.form-check').classList.remove('d-none');
                        }
                    }
                }
            }
            if(type === 'delete'){
                $this = $("#attendanceDelete");
            }

            if($this.find(".conflicts").length > 0){
                $this.find(".conflicts").remove();
            }

            if(isBackend) {
                $this.data('employee', empl);
                $this.attr('data-employee', empl);
            } else {
                $this.removeAttr('data-employee');
            }

            $this.modal({
                show: true,
                backdrop: 'static'
            });

            if (type === 'new') {
                clearForm($this);
                if (date !== "" && date !== null) {
                    $("#attendance_startDate").val(date);
                }
                // if home office is not available
                // remove home office option from DOM
                if(hoAvailable === false) {
                    const hoType = document.querySelector('#attendanceNew #attendance_attendanceTypeId input[value="3"]');
                    hoType !== null ? hoType.closest('.form-check').remove() : null;
                }
            }

            if($this.attr("id") === "attendanceInfo" && type === "info"){

                const id = $elem.data('attendance'),
                    $body = $this.find('.modal-body');
                $body.empty();
                $.ajax({
                    url: calendarAjaxUrl + "getbyid",
                    type: 'post',
                    dataType: 'json',
                    data: {"id": id}
                }).done(function(data){
                    if(data['login'] === false){
                        $(location).attr('href', '/employee/login');
                        return true;
                    }
                    if(data['success'] === false) {
                        return false;
                    }
                    const d = data['data'];
                    let end = d['endDate'] === null ? "" : "<p class='m-0'><strong>To</strong> <span class='endDate'>" + d['endDate'] + "</span></p>",
                        from = d['endDate'] === null ? "Date" : "From",
                        parkingLotFree = d['parkingLotFree'] === null ? "" : d['parkingLotFree'],
                        parkingLotFreeHtml = "",
                        comment = d['comment'] === null ? "" : "<p class='m-0'><strong>Comment</strong> <span>" + d['comment'] + "</span></p>",
                        zone = d['zone'] === null ? "" : "<p class='m-0'><strong>Zone</strong> <span class='zone-title'>" + d['zone'] + "</span></p>",
                        homeOfficeStatus = d['homeOffice'] !== null ? d['homeOffice']['statusText'] : null;

                    if(parkingLotFree !== null) {
                        if(parkingLotFree === true && d['isPresent'] === true) {
                            parkingLotFreeHtml = "<p class='m-0'><i class='fal fa-parking'></i> <strong>Parking lot</strong><br><small class='form-text text-muted'>You are present, but your parking lot is available to your colleagues or visitors.</small></p><hr>";
                        }
                        if(parkingLotFree === false && (d['isPresent'] === null || d['isPresent'] === false)) {
                            parkingLotFreeHtml = "<p class='mt-3 mb-3'><i class='fal fa-parking'></i> <strong>Parking lot</strong><br><small class='form-text text-muted'>You are not present, but your parking lot is occupied.</small></p><hr>";
                        }
                    }

                    $body.html(
                        "<div class='row grey'>" +
                        "<div class='col pb-3'>" +
                        "<p class='lead mb-2'><span class='badge badge-secondary gray-dark' style='background: " + d['color'] + ";'><i class='" + d['icon'] + "'></i> " + d['title'] + "</span></p>" +
                        (homeOfficeStatus !== null ? `<p class='m-0'><strong>Mobile work</strong><span class="ml-2">${homeOfficeStatus}</span></p>` : '') +
                        "<p class='m-0'><strong>" + from + "</strong> <span class='startDate'>" + d['startDate'] + "</span><br>" +
                        end + comment + zone + "<hr class='mt-3'>" + parkingLotFreeHtml +
                        "<p class='modal-actions'><a href='#' class='btn btn-light edit-attendance-btn' data-id='" + d['id'] + "'>Edit</a>" +
                        " <a href='#' class='btn btn-light delete-attendance-btn' data-id='" + d['id'] + "'>Delete</a></p>" +
                        "</div>" +
                        "</div>" +
                        "<div class='row'><div class='col mt-3'>" +
                        "<a href='#' class='btn btn-primary btn-block add-attendance-btn'>New calendar entry</a>" +
                        "</div></div>"
                    );
                });
            }

            $this.find('.form-error').empty();

            let defaultDate = moment().format('DD.MM.YYYY'),
                $start = $this.find('#attendance_startDate'),
                startVal = $start !== undefined && $start.length > 0 ? $start.val() : defaultDate;

            if(startVal !== undefined && startVal.length === 10 && moment(startVal, 'DD.MM.YYYY').isValid()){
                defaultDate = startVal;
            }

            $(".datepicker").datepicker('destroy');
            $(".datepicker").datepicker({
                format: 'dd.mm.yyyy',
                autoclose: true,
                clearBtn: true,
                todayBtn: false,
                todayHighlight: true,
                weekStart: 1,
                disableTouchKeyboard: true,
                daysOfWeekDisabled: [0,6],
                startDate: moment().subtract('1', 'weeks').subtract('1', 'day').format('DD.MM.YYYY'),
                setDate: moment(defaultDate, 'DD.MM.YYYY').format('DD.MM.YYYY'),
                endDate: false,
                defaultViewDate: defaultDate,
                updateViewDate: false,
                maxViewMode: 2,
            }).on('changeDate', function(e){
                let $target = $(e.target),
                    $form = $target.closest('form');
                if($target.attr('id') === 'attendance_startDate'){
                    let startDate = $target.val();
                    const endDateField = $form.find("#attendance_endDate"),
                        attendanceType = $form.find('#attendance_attendanceTypeId input:checked').val();
                    // if home office is checked
                    // then not set focus on end date field
                    if(attendanceType !== '3') {
                        endDateField.focus();
                        endDateField.datepicker('update', '').datepicker('setStartDate', moment(startDate, 'DD.MM.YYYY').format('DD.MM.YYYY')).datepicker('setEndDate');
                    }
                }
            }).on('show', function(e) {
                const $target = $(e.target),
                    ajaxUrl = isBackend ? '/backend/' : '/employee/',
                    hoType = 
                        document.querySelector('#attendanceEdit #attendance_attendanceTypeId input[value="3"]') ?? 
                        document.querySelector('#attendanceNew #attendance_attendanceTypeId input[value="3"]');
                let employee = null;
                if(isBackend) {
                    employee = $target.closest('.modal').data('employee');
                }
                $.ajax({
                    url: ajaxUrl + 'calendar/get-holidays',
                    type: 'post',
                    dataType: 'json',
                    data: {employee: employee}
                }).done(function(data){
                    $.each(data['data'], function(k, v){
                        $target.datepicker('setDatesDisabled', v);
                    });
                    if(hoType?.checked) {
                        $target.datepicker('setStartDate', moment().add(1, 'day').format('DD.MM.YYYY'));
                        $target.datepicker('setEndDate', moment().add(1, 'day').add(4, 'weeks').format('DD.MM.YYYY'));
                        if(moment().hour() > 15) {
                            $target.datepicker('setStartDate', moment().add(2, 'days').format('DD.MM.YYYY'));
                            $target.datepicker('setEndDate', moment().add(2, 'days').add(4, 'weeks').format('DD.MM.YYYY'));
                        }
                    }
                });
            });

            $this.find('form').find('#attendance_endDate').datepicker('setStartDate', moment(defaultDate, 'DD.MM.YYYY').format('DD.MM.YYYY'));

            let idEdit = $this.find("#attendance_id-edit").val(),
                start = $this.find("#attendance_startDate").val(),
                end = $this.find("#attendance_endDate").val(),
                typeId = $this.find("#attendance_attendanceTypeId input:checked").val(),
                $employees = $('#employees'),
                isModal = !(type !== "delete"),
                employee = isBackend ? empl : ($employees.length ? $employees.val() : $('.modal.show').data('id'));

            checkConflicts(idEdit, start, end, employee);
            checkReservations(start, end, isModal, employee);
            checkZone(start, end, employee, typeId);
        },

        closeModal = function(){
            $('.modal').each(function () {
                $(this).removeAttr('data-employee');
                $(this).find('.form-error').empty();
                $(this).find('#attendance_endDate').closest('.form-group').removeClass('d-none');
                $(this).modal('hide');
            });
        },

        checkAttendanceFormInput = function(form){
            let html = '',
                startVal = form.find('#attendance_startDate').val(),
                endVal = form.find('#attendance_endDate').val(),
                typeVal = form.find('input[id^="attendance_attendanceTypeId_"]:radio:checked').val();

            if(startVal === '' || startVal === null || moment(startVal, 'DD.MM.YYYY', true).isValid() === false){
                html += "<p class='mb-1'>Please insert a valid start date <i>(format: dd.mm.yyyy)</i>.</p>";
            }
            if(endVal.length > 0 && moment(endVal, 'DD.MM.YYYY', true).isValid() === false){
                html += "<p class='mb-1'>Please insert a valid end date <i>(format: dd.mm.yyyy)</i>.</p>";
            }
            if(moment(endVal, 'DD.MM.YYYY', true).isValid() && moment(startVal, 'DD.MM.YYYY', true).isValid() && moment(startVal, 'DD.MM.YYYY').diff(moment(endVal, 'DD.MM.YYYY'), 'days') > 0){
                html += "<p class='mb-1'>The start date can not be before the end date. Please correct your entries.</p>";
            }
            if(typeVal === '' || typeVal === null || typeVal === 0 || typeVal === undefined){
                html += "<p class='mb-1'>Please select one reason.</p>";
            }

            return html;
        },

        getChartData = function($wrapper, type){
            $wrapper.height = 100;
            let nextMonth = moment().add(1, 'M').format('MMMM'),
                nextMonthDate = moment().add(1, 'M').startOf('month').format('YYYY-MM-DD'),
                nextWeek = moment().add(1, 'weeks').isoWeek(),
                monthOfNextWeek = moment().add(1, 'weeks').startOf('month').format('YYYY-MM-DD');
            if($wrapper.length > 0) {
                $.ajax({
                    url: "/employee/calendar/getchart",
                    type: 'post',
                    async: true,
                    dataType: 'json',
                    data: {type: type}
                }).done(function (data) {
                    data = getArrayFrom(data);
                    if($('.next-' + type + '-hl').length > 0){
                        let html = type === 'week' ? ' <em>(<a href="/employee/calendar?month=' + monthOfNextWeek + '">cw&thinsp;' + nextWeek + '</a>)</em>' : ' <em>(<a href="/employee/calendar?month=' + nextMonthDate + '">' + nextMonth + '</a>)</em>';
                        $('.next-' + type + '-hl').html('Next ' + type + html);
                    }

                    new Chart($wrapper, {
                        type: 'horizontalBar',
                        data: {
                            datasets: data
                        },
                        options: {
                            legend: {
                                position: 'bottom',
                                onClick: null
                            },
                            scales: {
                                xAxes: [{
                                    stacked: true,
                                    ticks: {
                                        min: 0,
                                        stepSize: 10,
                                        max: 100
                                    },
                                    scaleLabel: {
                                        display: true,
                                        labelString: '%'
                                    },
                                }],
                                yAxes: [{
                                    stacked: true
                                }]
                            },
                            tooltips: {
                                enabled: true,
                                mode: 'x',
                                callbacks: {
                                    label: function(tooltipItems, data) {
                                        return data.datasets[tooltipItems.datasetIndex].label +': ' + tooltipItems.xLabel + ' %';
                                    }
                                }
                            },
                            responsive: true,
                            maintainAspectRatio: false
                        }

                    });
                });
            }
        },
        getDashboardCalendar = function(data){
            let i,
                interval = 7,
                monthChange = interval,
                now = moment(),
                month = now.month() + 1,
                initMonthDate = moment().format('YYYY-MM-DD'),
                html = "";

            html += "<p class='grey'>Next seven days</p>";
            html += "<table><tr>";
            let thisMonth = null,
                thisMonthDate = null;
            for(i = 0; i < interval; i++){
                let style = " style=''";
                let thisDate = moment().add(i, 'day'),
                    thisDay = thisDate.date(),
                    weekDay = thisDate.day(),
                    weekendCls = '';

                thisMonth = thisDate.month() + 1;
                thisMonthDate = thisDate.format('YYYY-MM-DD');

                if(weekDay === 0 || weekDay === 6 || (data !== undefined && data[i]['holiday'] !== undefined)){
                    weekendCls = 'is-weekend';
                } else {
                    if(data !== undefined && data[i][0] !== undefined){
                        let color = data[i][0]['color'];
                        style = " style='background-color: " + color + ";'";
                    }
                }

                if(thisDay === 1 && month !== thisMonth){
                    monthChange = i;
                }

                html += "<td class='" + weekendCls + "'" + style + ">" + thisDay + "</td>";
            }

            html += "</tr><tr><td class='month-name text-uppercase' colspan='" + monthChange + "'><p><a href='/employee/calendar?month=" + initMonthDate + "'>" + moment(month, 'M').format('MMMM') + "</a></p></td>";

            if(monthChange !== interval && month !== thisMonth){
                let diff = interval - monthChange;
                html += "<td class='month-name next-month text-uppercase' colspan='" + diff + "'><p><a href='/employee/calendar?month=" + thisMonthDate + "'>" + moment(thisMonth, 'M').format('MMMM') + "</a></p></td>"
            }

            html += "</tr></table>";

            $dashboardCalendar.find('.calendar-wrapper').html(html);

        },
        getDashboardData = function(){
            if($dashboardCalendar.length > 0){
                $.ajax({
                    url: '/employee/calendar/getdashboard',
                    type: 'post',
                    dataType: 'json'
                }).done(function(data){
                    getDashboardCalendar(data);
                });
            }
        },

        saveNewAttendance = function($elem) {

            let $employees = $('#employees'),
                $modal = $('.modal.show'),
                employee = isBackend ? $modal.data('employee') : ($employees.length ? $employees.val() : $modal.data('id')),
                represented = isBackend ? 0 : $employees.find('option:selected').data('represented') ?? 0,
                deputy = 0,
                parkingLot = isBackend ? null : $employees.find('option:selected').data('parkinglot') ?? null,
                ajaxUrl = isBackend ? '/backend/calendar/' : '/employee/calendar/';

            if(represented !== 0 && $employees.length) {
                parkingOwner = parkingLot !== null;
                deputy = $calendar.data('employee');
            }

            let $form = $elem.closest('form'),
                errHtml = checkAttendanceFormInput($form);
            $formErr.empty();
            if (errHtml.length > 0) {
                $formErr.html("<div class='alert alert-danger' role='alert'>" + errHtml + "</div>");
                return false;
            }

            $elem.attr("disabled", true);
            $.ajax({
                url: ajaxUrl + 'new?employee=' + employee + '&parkingOwner=' + parkingOwner + '&deputy=' + deputy,
                type: 'post',
                data: $form.serialize()
            }).done(function (data) {

                if(data.success === false) {
                    $elem.attr("disabled", false);
                    $(".modal").find('.form-error').html(`<div class='alert-danger'><b>Saving failed!</b><br>${data.status}</div>`);
                    return;
                }

                if (data.status === 'saved') {
                    $elem.attr("disabled", false);
                    closeModal();
                    if ($calendar.length > 0) {
                        const activeMonth = moment($('#attendance_startDate').val(), 'DD.MM.YYYY').format('YYYY-MM-DD');
                        getCalendarEvents(employee, activeMonth);
                    } else {
                        if ($chartWrapper.length > 0) {
                            getChartData($chartWrapper, 'week');
                        }
                        if ($chartMonthWrapper.length > 0) {
                            getChartData($chartMonthWrapper, 'month');
                        }
                        if(!isBackend) {
                            getDashboardData();
                        } else {
                            getEmployeeCalendarHeader(month);
                            getEmployeeCalendar(moment(month).format('YYYY'), moment(month).format('MM'));
                        }
                    }
                }
                if (data.status === 'invalid') {
                    $elem.attr("disabled", false);
                    $(".modal").find('.form-error').html("<div class='alert-danger'>Saving failed!</div>");
                }
                $elem.attr("disabled", false);
            });
        },

        getAttendanceCommentField = function(before, val, editForm){
            if(editForm === false){
                $('.attendance-comment').remove();
            } else {
                $('.attendance-comment-info').remove();
            }

            if($('.car-is-there-wrapper').length > 0 && editForm) {
                before = $('.car-is-there-wrapper');
            }

            $.ajax({
                url: '/employee/calendar/has-comments',
                type: 'post',
                dataType: 'json',
                data: { hasComments: val }
            }).done(function(data){
                if(editForm){
                    $('.attendance-comment textarea').attr('disabled', 'disabled');
                }
                if(data['data'] === true){
                    if(editForm && $('.attendance-comment').length > 0){
                        $('.attendance-comment textarea').removeAttr('disabled');
                    } else {
                        before.after("<div class='form-row attendance-comment'><div class='col'><div class='form-group'><label for='attendance_comment' class='form-control-label'>Comment</label><textarea id='attendance_comment' name='attendance[comment]' class='form-control'></textarea></div></div></div>");
                    }
                } else {
                    if(editForm){
                        $('.attendance-comment label').append(" <span class='d-block attendance-comment-info'><small><strong><i class='fas fa-exclamation-triangle'></i> The comment will be removed, if you choose this reason.</strong></small></span>");
                    }
                }
            });
        },

        getCarIsThereField = function(elem, type) {
            let represented = $employees.find('option:selected').data('represented'),
                parkingLot = $employees.find('option:selected').data('parkinglot'),
                available = false;

            if(isBackend) {
                const $modal = $('.modal.show'),
                    thisEmployee = $modal.data('employee'),
                    hasParking = $('.employee-wrapper .employee[data-id="' + thisEmployee + '"]').hasClass('has-parking');
                if(hasParking) {
                    available = true;
                }
            } else {
                if ($("body").hasClass('has-parkinglot')) {
                    available = true;
                }
                if (parseInt(represented) === parseInt(thisEmployee) && parkingLot > 0) {
                    available = true;
                }
            }
            if (!available) {
                return true;
            }
            let $optWrapper = $('.car-is-there-wrapper');
            if($optWrapper.length > 0) {
                $optWrapper.remove();
            }
            $.ajax({
                url: '/employee/calendar/is-present',
                type: 'post',
                dataType: 'json',
                async: true,
                data: {type: type}
            }).done(function(data){
                if(data['success'] === false) {
                    return false;
                }

                let option = "";

                if(data['data'] === true) {
                    option = "<div class='form-check form-check-inline'><input class='form-check-input' id='carIsThere1' type='radio' name='parkingLotFree' value='1'> <label class='form-check-label' for='parkingLotFree1'>Yes</label></div>" +
                        "<div class='form-check form-check-inline'><input class='form-check-input' id='parkingLotFree2' type='radio' name='parkingLotFree' value='0' checked='checked'> <label class='form-check-label' for='parkingLotFree2'>No</label></div>";
                } else {
                    option = "<div class='form-check form-check-inline'><input class='form-check-input' id='parkingLotFree1' type='radio' name='parkingLotFree' value='1' checked='checked'> <label class='form-check-label' for='parkingLotFree1'>Yes</label></div>" +
                        "<div class='form-check form-check-inline'><input class='form-check-input' id='parkingLotFree2' type='radio' name='parkingLotFree' value='0'> <label class='form-check-label' for='parkingLotFree2'>No</label></div>";
                }

                let html = "<div class='car-is-there-wrapper form-row'><div class='col-12'><hr class='mt-3 mb-3'><p><i class='fal fa-parking'></i> Is your parking lot free?</p></div><div class='col-12'>" + option + "<hr class='mt-3 mb-3'></div></div>";
                elem.after(html);
            });
        },

        getPrimaryLocation = function($employeeTable) {
            $employeeTable.find('.cell-location').each(function(k, v){
                const $cell = $(this),
                    $locations = $cell.find('.employee-location');

                if($locations.length <= 1) {
                    return true;
                }

                $locations.each(function(key, val){
                    const $location = $(this),
                        location = $location.text(),
                        primaryCls = $location.hasClass('is-primary-location') ? 'btn-primary' : 'btn-outline-primary',
                        primaryIcon = $location.hasClass('is-primary-location') ? '<i class="fal fa-check"></i> ' : '',
                        id = $location.data('location');
                    $location.html("<a href='#' class='btn " + primaryCls + " btn-sm primary-location mb-1' data-location='" + id + "'>" + primaryIcon + location + "</a>");
                });
            });
        };


    // Deactivated types
    const $deactivated_types = $('.deactivated-types');
    if($deactivated_types.length)
    {
        $deactivated_types.popover();
    }


    // Edit attendance
    $(document).on('change', '.attendance-edit-form #attendance_attendanceTypeId .form-check-input', function(e){
        let $this = $(this),
            $modal = $(this).closest('.modal.show'),
            $wrapper = $this.closest('.form-row'),
            $employees = $('#employees'),
            employee = isBackend ? $modal.data('employee') : ($employees.length ? $employees.val() : $('.modal.show').data('id')),
            val = $wrapper.find('input:checked').val();

        if(val !== null && val > 0){
            getAttendanceCommentField($wrapper, val, true);
            getCarIsThereField($wrapper, val);
            const startDateField = $modal.find('#attendance_startDate'),
                endDateField = $modal.find('#attendance_endDate');
            // remove `disabled` attribute on `startDateField`
            startDateField.prop('disabled', false);
            // if home office is checked
            // set start and end date
            if (val === '3') {
                startDateField.datepicker('setStartDate', moment().add(1, 'day').format('DD.MM.YYYY'));
                startDateField.datepicker('setEndDate', moment().add(1, 'day').add(4, 'weeks').format('DD.MM.YYYY'));
                if(moment().hour() > 15) {
                    startDateField.datepicker('setStartDate', moment().add(2, 'days').format('DD.MM.YYYY'));
                    startDateField.datepicker('setEndDate', moment().add(2, 'days').add(4, 'weeks').format('DD.MM.YYYY'));
                }
                endDateField.datepicker('setEndDate', moment().add(4, 'weeks').format('DD.MM.YYYY'));
                endDateField.closest('.form-group').addClass('d-none');
            } else {
                startDateField.datepicker('setStartDate', moment().subtract('1', 'weeks').subtract('1', 'day').format('DD.MM.YYYY')),
                startDateField.datepicker('setEndDate', null);
                endDateField.datepicker('setEndDate', null);
                endDateField.closest('.form-group').removeClass('d-none');
            }
        }
        checkZone($modal.find('#attendance_startDate').val(), $modal.find('#attendance_endDate').val(), employee, val);
    });

    $(document).on('submit', '.attendance-edit-form', function(e){
        e.preventDefault();
        return false;
    });
    $(document).on('click', '#attendanceEdit.show #attendance_edit', function(e){
        e.preventDefault();
        const id = $("#attendance_id-edit").val(),
            $this = $(this),
            $modal = $this.closest('.modal.show'),
            $form = $this.closest('form'),
            errHtml = checkAttendanceFormInput($form);

        let $employees = $('#employees'),
            employee = isBackend ? $modal.data('employee') : $employees.val(),
            represented = isBackend ? 0 : $employees.find('option:selected').data('represented') ?? 0,
            deputy = 0,
            parkingLot = isBackend ? null : $employees.find('option:selected').data('parkinglot') ?? null,
            ajaxUrl = isBackend ? '/backend/' : '/employee/';

        if(represented !== 0){
            parkingOwner = parkingLot !== null;
            deputy = $calendar.data('employee');
        }

        $formErr.empty();
        if(errHtml.length > 0){
            $form.find('.form-error').html("<div class='alert alert-danger' role='alert'>" + errHtml + "</div>");
            return false;
        }

        $this.attr("disabled",true);
        $.ajax({
            url: ajaxUrl + 'calendar/edit?id=' + id + '&parkingOwner=' + parkingOwner + '&employee=' + employee + '&deputy=' + deputy,
            type: 'post',
            data: $form.serialize()
        }).done(function(data){

            if(data.success === false) {
                $this.attr("disabled",false);
                $(".modal.show").find('.form-error').html(`<div class='alert-danger'><b>Saving failed!</b><br>${data.status}</div>`);
                return;
            }

            if(data.status === 'edited'){
                $this.attr("disabled",false);
                if(isBackend) {
                    getEmployeeCalendarHeader(month);
                    getEmployeeCalendar(moment(month).format('YYYY'), moment(month).format('MM'));
                } else {
                    const activeMonth = moment($('#attendanceEdit #attendance_startDate').val(), 'DD.MM.YYYY').format('YYYY-MM-DD');
                    getCalendarEvents(employee, activeMonth);
                }
                closeModal();
                $("#attendanceEdit").remove();
            }
            if(data.status === 'invalid'){
                $this.attr("disabled",false);
                $(".modal.show").find('.form-error').html("<div class='alert-danger'>Saving failed!</div>");
            }
            $this.attr("disabled",false);
        });

    });


    // Delete attendance
    let $oldActions;
    $(document).on('click', '.delete-attendance-btn', function(e){
        e.preventDefault();
        const $this = $(this),
            id = $this.data('id'),
            $actions = $this.closest('.modal-actions');

        let $modal = $this.closest('.modal-body'),
            $modalWrapper = $modal.closest('.modal'),
            empl = null,
            startDate = $modal.find('.startDate').text(),
            endDate = $modal.find('.endDate').text();

        if(isBackend) {
            empl = $modalWrapper.data('employee');
        }

        $oldActions = $actions.clone();
        $actions.empty();

        $actions.html("<p><span class='blue'>Do you really want to delete this entry?</span></p><p><a class='yes-delete-attendance btn btn-light' data-id='" + id + "'>Yes</a> <a class='close-modal-btn go-back btn btn-light'>No</a></p>");

        checkReservations(startDate, endDate, true, empl);
    });

    // Delete attendance
    $(document).on('click', '.yes-delete-attendance', function(e){
        e.preventDefault();
        const $this = $(this),
            $modal = $this.closest('.modal-body'),
            $modalWrapper = $modal.closest('.modal.show'),
            $deleteReservations = $modal.find('#delete-reservations'),
            id = $this.data('id');

        let deleting = null,
            $reservations = $('input.reservations'),
            reservations = [];

        if($deleteReservations.length > 0 && $deleteReservations.prop('checked')) {
            deleting = 1;
        }

        if($reservations.length > 0) {
            $reservations.each(function(k, v){
                let $this = $(this);
                reservations.push($this.val());
            });
        }

        let $employees = $('#employees'),
            employee = isBackend ? $modalWrapper.data('employee') : $employees.val(),
            ajaxUrl = isBackend ? '/backend/' : '/employee/';

        $.ajax({
            url: ajaxUrl + 'calendar/delete',
            type: 'post',
            dataType: 'json',
            data: {id: id, deleteReservations: deleting, reservations: reservations}
        }).done(function(data){
            if(data['success'] === true){
                const activeMonth = moment($('.modal.show .startDate').text(), 'DD.MM.YYYY').format('YYYY-MM-DD');
                closeModal();
                if(isBackend) {
                    getEmployeeCalendarHeader(month);
                    getEmployeeCalendar(moment(month).format('YYYY'), moment(month).format('MM'));
                } else {
                    getCalendarEvents(employee, activeMonth);
                }
            }
        });
    });

    // Close delete dialog
    $(document).on('click', '.close-modal-btn.go-back', function(e){
        e.preventDefault();
        const $this = $(this),
            $actions = $this.closest('.modal-actions');

        $actions.html($oldActions);
    });

    // change dates in modal
    // check conflicts
    $(document).on('keyup change', '#attendance_startDate, #attendance_endDate', function(e){
        e.preventDefault();
        let $this = $(this),
            $parent = $this.closest('form'),
            $modal = $this.closest('.modal.show'),
            id = $parent.find("#attendance_id-edit").val(),
            start = $parent.find("#attendance_startDate").val(),
            end = $parent.find("#attendance_endDate").val(),
            type = $modal.find('#attendance_attendanceTypeId input:checked').val(),
            $employees = $('#employees'),
            employee = isBackend ? $modal.data('employee') : ($employees.length ? $employees.val() : $('.modal.show').data('id'));

        checkConflicts(id, start, end, employee);
        checkReservations(start, end, false, employee);
        checkZone(start, end, employee, type);
    });


    let oldModal = null;
    $(document).on("click", ".add-attendance-btn, .add-new-attendance", function(e){
        e.preventDefault();
        let $modalBody = $(e.target).closest('.modal-body'),
            $modal = $modalBody.closest('.modal'),
            employee = isBackend ? $modal.data('employee') : null,
            date = $modalBody.length > 0 ? $modalBody.find('.startDate').text() : null,
            $openModal = $("#attendanceInfo.show");
        if($openModal.length > 0){
            oldModal = $openModal.clone();
            closeModal();
        } else {
            oldModal = null;
        }

        if(clickedDate !== null && clickedDate !== undefined && clickedDate.length === 10) {
            date = clickedDate;
        }

        openModal('new', e.target, date, employee);
    });

    // open edit modal from info modal
    $(document).on('click', '.edit-attendance-btn', function(e){
        e.preventDefault();
        const id = $(this).data('id'),
            $openModal = $("#attendanceInfo.show"),
            modal = $("#attendanceEdit"),
            employee = isBackend ? $openModal.data('employee') : null;
        $.ajax({
            url: "/employee/calendar/getEditForm",
            type: 'post',
            async:true,
            dataType: 'json',
            data: {"id": id}
        }).done(function(data){
            if($openModal.length > 0){
                oldModal = $openModal.clone();
                closeModal();
            } else {
                oldModal = null;
            }
            if(modal.length > 0) {
                closeModal();
                modal.remove();
            }
            $('body').append(data['response']);
            openModal("edit", e.target, null, employee);
        });
    });

    // close edit or new modal window
    // if user came from info modal, go back to info modal
    $(document).on('click', '#attendanceEdit .go-back, #attendanceNew .go-back', function(e){
        e.preventDefault();
        if($(this).closest("#attendanceEdit").length > 0){
            closeModal();
            $("#attendanceEdit").remove();
        }
        if($(this).closest("#attendanceNew").length > 0){
            closeModal();
        }
        if(oldModal !== null){
            $("#attendanceInfo").modal({
                show: true,
                backdrop: 'static'
            });
        }
    });

    $(document).on('click', '#attendanceInfo .close', function(e){
        oldModal = null;
    });

    // Add new attendance
    // User can add attendances on multiple pages
    $(document).on('change', '.attendance-form #attendance_attendanceTypeId .form-check-input', function(e){
        let $this = $(this),
            $modal = $(this).closest('.modal'),
            $wrapper = $this.closest('.form-row'),
            $employees = $('#employees'),
            employee = isBackend ? $modal.data('employee') : ($employees.length ? $employees.val() : $('.modal.show').data('id')),
            val = $wrapper.find('input:checked').val();
        
        const startDateField = $modal.find('#attendance_startDate'),
            endDateField = $modal.find('#attendance_endDate');

        if(val !== null && val > 0){
            getAttendanceCommentField($wrapper, val, false);
            getCarIsThereField($wrapper, val);
            // if home office is selected
            // then set the start and end date of the datepicker
            // otherwise reset start and end date to the default values
            if (val === '3') {
                endDateField.closest('.form-group').addClass('d-none');
                startDateField.datepicker('setStartDate', moment().add(1, 'day').format('DD.MM.YYYY'));
                startDateField.datepicker('setEndDate', moment().add(1, 'day').add(4, 'weeks').format('DD.MM.YYYY'));
                if(moment().hour() > 15) {
                    startDateField.datepicker('setStartDate', moment().add(2, 'days').format('DD.MM.YYYY'));
                    startDateField.datepicker('setEndDate', moment().add(2, 'days').add(4, 'weeks').format('DD.MM.YYYY'));
                }
                endDateField.datepicker('setEndDate', moment().add(4, 'weeks').format('DD.MM.YYYY'));
            } else {
                endDateField.closest('.form-group').removeClass('d-none');
                startDateField.datepicker('setStartDate', moment().subtract('1', 'weeks').subtract('1', 'day').format('DD.MM.YYYY')),
                startDateField.datepicker('setEndDate', null);
                endDateField.datepicker('setEndDate', null);
            }

            
        }
        checkZone($modal.find('#attendance_startDate').val(), $modal.find('#attendance_endDate').val(), employee, val);
    });

    $attendanceNewForm.on('submit', function(e){
        e.preventDefault();
        return false;
    });
    $attendanceSaveBtn.on('click', function(e){
        e.preventDefault();
        let $this = $(this);
        saveNewAttendance($this);
    });


    $(document).on('mouseover touchstart', '.has-comment', function (e) {
        let target = $(e.currentTarget),
            comment = target.data('comment').length > 0 ? target.data('comment') : undefined;
        if ($('body').hasClass('has-touch') === false) {
            openTooltip(target, comment, true);
        }
    });

    $(document).on('mouseleave touchend', '.has-comment', function (e) {
        let target = $(e.currentTarget);
        closeTooltip(target);
    });




    // Backend
    if($(".backend").length > 0){
        const hoDaysPerWeek = document.getElementById('location_hoDaysPerWeek');
        const hasHomeOfficeRequests = document.getElementById('location_hasHomeOfficeRequest');
        if(hoDaysPerWeek !== null) {
            hasHomeOfficeRequests.checked === true ?
                hoDaysPerWeek.disabled = false :
                hoDaysPerWeek.disabled = true;
        }

        hasHomeOfficeRequests?.addEventListener('change', (e) => {
            hoDaysPerWeek !== null ? (
                e.target.checked === true ? hoDaysPerWeek.disabled = false : hoDaysPerWeek.disabled = true
            ) : null;
        });




        // Distribution lists

        // Form visualization
        const $distributionListRecipients = $('#distribution_lists_recipients');
        if($distributionListRecipients.length) {
            const $checked = $distributionListRecipients.find('.custom-checkbox input:checked');
            $checked.each(function(k,v){
                const $this = $(this);
                $this.next('label').addClass('font-weight-bold');
                $this.closest('.custom-checkbox').addClass('light-blue-background');
            });
            $(document).on('click', '.custom-checkbox input[type="checkbox"]', function(e){
                const $this = $(this);
                if($this.prop('checked')) {
                    $this.next('label').addClass('font-weight-bold');
                    $this.closest('.custom-checkbox').addClass('light-blue-background');
                } else {
                    $this.next('label').removeClass('font-weight-bold');
                    $this.closest('.custom-checkbox').removeClass('light-blue-background');
                }
            });
        }

        // zones

        // Search employees
        const employeeZoneSearchField = $('.employee-zone-search-field'),
            $employeeZone = $(".employee-zone-list"),
            $tempTable = $employeeZone.find('.employee-zone-table').clone();
        if(employeeZoneSearchField.length) {
            employeeZoneSearchField.on('keyup', function(e){

                let $this = $(this),
                    $group = $this.closest('.input-group'),
                    $icon = $group.find('.fal'),
                    path = window.location.pathname,
                    val = $this.val();

                if(val.length >= 3) {
                    $icon.removeClass('fa-search');
                    $icon.addClass('fa-spinner-third');
                    $.ajax({
                        url: path + "/search",
                        type: "post",
                        dataType: "json",
                        data: {search: val}
                    }).done(function(data){

                        if(data['success'] === false || data['count'] === 0) {
                            $icon.removeClass('fa-spinner-third');
                            $icon.addClass('fa-search');
                            $employeeZone.html("<div class='col mt-3'><div class='alert alert-danger w-100'><i class='fal fa-exclamation-triangle'> Nothing found.</i></div></div>");
                        } else {

                            let $html = $($.parseHTML(data['data'])),
                                $table = $html.find('.employee-zone-table');
                            $employeeZone.html($table);
                            $icon.removeClass('fa-spinner-third');
                            $icon.addClass('fa-search');
                            $table.find("[data-toggle='popover']").popover();
                        }
                    });

                } else {
                    $employeeZone.html($tempTable);
                    $icon.removeClass('fa-spinner-third');
                    $icon.addClass('fa-search');
                }

            });
        }

        if($employeeZone.length) {
            $(document).on('click', '.employee-zone-add-btn', function(e){
                e.preventDefault();
                const employee = $(this).data('employee'),
                    path = window.location.pathname;

                $.post(path + "/add/" + employee)
                    .done(function(data){
                        if(data['success'] === false) {
                            return false;
                        }
                        const $row = $(".employee-zone-list tr.is-employee[data-id='" + employee + "']");

                        $row.replaceWith(data['data']);
                    });

            });

            // remove employee from zone
            $(document).on('click', '.employee-zone-remove-btn', function(e){
                e.preventDefault();
                const id = $(this).data('id'),
                    path = window.location.pathname;

                $.post(path + "/remove/" + id)
                    .done(function(data){
                        if(data['success'] === false) {
                            return false;
                        }
                        const $row = $(".employee-zone-list tr.is-employee[data-id='" + data['data']['employee'] + "']");

                        $row.replaceWith(data['data']['template']);
                    });

            });

            // add employee as responsible
            $(document).on('click', '.employee-zone-responsible-btn', function(e){
                e.preventDefault();
                const id = $(this).data('id'),
                    $icon = $(this).find('.fa-user-crown'),
                    path = window.location.pathname;

                let is_admin = 0;

                if($icon.hasClass('fal')) {
                    is_admin = 1;
                }

                $.post(path + "/edit/" + id, {is_admin: is_admin})
                    .done(function(data){
                        if(data['success'] === false) {
                            return false;
                        }
                        const $row = $(".employee-zone-list tr.is-employee[data-id='" + data['data']['employee'] + "']");

                        $row.replaceWith(data['data']['template']);
                    });

            });
        }

        // zone limits

        const $zoneLimitWrapper = $('.zone-limit-wrapper');
        if($zoneLimitWrapper.length) {

            const getForm = function(zone_limit = 0) {
                const path = window.location.pathname;

                $.post(path + '/dialog', {id: zone_limit})
                    .done(function(data){
                        $('body').append(data['data']);
                        const $modal = $('#zoneLimitDialog');
                        if(zone_limit !== 0) {
                            $modal.attr("data-id", zone_limit);
                            $modal.data('id', zone_limit);
                        }
                        $modal.modal('show');
                        $('.datepicker').datepicker({
                            format: 'dd.mm.yyyy',
                            autoclose: true,
                            todayBtn: true,
                            todayHighlight: true,
                            weekStart: 1,
                            daysOfWeekHighlighted: [0,6],
                            maxViewMode: 2,
                        });
                    });
            }

            $(document).on('click', '.zone-limit-add-dialog', function(e) {
                e.preventDefault();
                getForm();
            });
            $(document).on('click', '.zone-limit-edit-btn', function(e) {
                e.preventDefault();
                const id = $(this).data('id');
                getForm(id);
            });
            $(document).on('click', '.zone-limit-delete-btn', function(e) {
                e.preventDefault();
                const id = $(this).data('id'),
                    path = window.location.pathname;

                $.post(path + "/delete-dialog/" + id)
                    .done(function(data){
                        $('body').append(data['data']);
                        const $modal = $('#zoneLimitDeleteDialog');
                        $modal.modal('show');
                    });
            });

            $(document).on('submit', '.zone-limit-dialog', function(e){
                e.preventDefault();
                const $modal = $(this).closest('.modal.show'),
                    path = window.location.pathname;

                $.post(path + '/add', $(this).serialize())
                    .done(function(data){
                        if(data['success' === false]) {
                            return false;
                        }

                        $modal.modal('hide');
                        $modal.on('hidden.bs.modal', function(){
                            $(this).remove();
                            window.location.reload();
                        });
                    });
            });

            $(document).on('submit', '.zone-limit-edit-dialog', function(e){
                e.preventDefault();
                const $modal = $(this).closest('.modal.show'),
                    id = $modal.data('id'),
                    path = window.location.pathname;

                $.post(path + '/edit/' + id, $(this).serialize())
                    .done(function(data){
                        if(data['success' === false]) {
                            return false;
                        }

                        $modal.modal('hide');
                        $modal.on('hidden.bs.modal', function(){
                            $(this).remove();
                            window.location.reload();
                        });
                    });
            });

            $(document).on('click', '.zone-limit-delete-acception', function(e){
                e.preventDefault();
                const $modal = $(this).closest('.modal.show'),
                    id = $modal.data('id'),
                    path = window.location.pathname;

                $.post(path + '/delete/' + id)
                    .done(function(data) {
                        if(data['success' === false]) {
                            return false;
                        }

                        $modal.modal('hide');
                        $modal.on('hidden.bs.modal', function(){
                            $(this).remove();
                            window.location.reload();
                        });
                    });
            });

            $(document).on('click', '.modal-close', function(e) {
                const $modal = $(this).closest('.modal.show');
                $modal.modal('hide');
                $modal.on('hidden.bs.modal', function(){
                    $(this).remove();
                });
            });

        }


        // employee list
        const $employee_table = $('.employee-table');
        if($employee_table.length) {

            $(document).on('click', '.disclaimer-cancel', function(e){
                e.preventDefault();
                const $this = $(this),
                    $popover = $this.closest('.popover');

                $popover.popover('hide');
            });

            $(document).on('click', '.disclaimer-accept', function(e){
                e.preventDefault();
                const $this = $(this),
                    employee = $this.data('employee'),
                    path = window.location.pathname;
                $.post(path + '/disclaimer/' + employee)
                    .done(function(data){
                        if(data['success'] === false) {
                            return false;
                        }

                        $this.closest('.popover').popover('dispose');
                        $employee_table.find('tr[data-employee="' + employee + '"]').find('.fa-user-secret').remove();


                    });
            });

            $(document).on('click', '.lock-employee-btn', function(e){
                e.preventDefault();
                const $this = $(this),
                    employee = $this.data('employee'),
                    path = window.location.pathname;
                $.post(path + '/lock/' + employee)
                    .done(function(data){
                        if(data['success'] === false) {
                            return false;
                        }
                        $this.closest('.popover').popover('dispose');
                        const $row = $employee_table.find('tr[data-employee="' + employee + '"]'),
                            username = $row.find('.username-cell').text(),
                            $icon = $row.find('.employee-status');
                        $row.addClass('row-red');
                        $icon.replaceWith("<i class='fal fa-user-shield employee-unlock employee-status mr-2' data-toggle='popover' data-placement='bottom' data-html='true' data-content='<p>Do you really want to unlock the employee <b>" + username + "</b><hr><a href=\"#\" class=\"btn btn-danger btn-sm mr-1 employee-unlock-yes\" data-id=\"" + employee + "\"><i class=\"fal fa-check\"></i> Yes</a> <a href=\"#\" class=\"btn btn-secondary btn-sm employee-unlock-no\">No</a></p>'></i>");
                        $row.find('.employee-status').popover();

                    });
            });

        }




        $('[data-toggle="tooltip"]').tooltip();

        const $locationCountry = $('#location_country'),
            $holidays = $('.holidays');
        if($locationCountry.length) {
            $locationCountry.on('change', function(e) {
                const $this = $(this),
                    $form = $this.closest('form');
                let data = {};
                data[$locationCountry.attr('name')] = $locationCountry.val();

                $.ajax({
                    url: $form.attr('action'),
                    type: $form.attr('method'),
                    data: data,
                    dataType: "html"
                }).done(function(html) {
                    $('#location_region').replaceWith($(html).find('#location_region'));
                });
            });
        }

        if($holidays.length) {
            const $datepicker = $('.datepicker'),
                location = $holidays.data('location');
            $datepicker.on('changeDate', function(e) {
                const date = new Date(e.date),
                    year = date.getFullYear();
                $datepicker.html("<i class='fal fa-calendar-alt mr-1'></i> " + year);

                $.ajax({
                    url: '/backend/location/holidays/' + location,
                    type: 'post',
                    dataType: 'html',
                    data: {date: moment(year, 'YYYY').format('DD.MM.YYYY')}
                }).done(function(data) {
                    const $holidays = $('.holidays');
                    $holidays.replaceWith($(data).find('.holidays'));
                });
            });

            $(document).on('click', '.add-api-holidays', function(e) {
                e.preventDefault();
                const $this = $(this),
                    $parent = $this.closest('.holidays'),
                    location = $parent.data('location'),
                    dateVal = $datepicker.datepicker('getDate');

                if(dateVal === null) {
                    return false;
                }

                const year = new Date(dateVal).getFullYear();

                $.ajax({
                    url: '/backend/location/holidays/add-api-holidays',
                    type: 'post',
                    dataType: 'json',
                    data: {location: location, year: year}
                }).done(function (data) {
                    if (data['success'] === false || data['data'] === false) {

                    }

                    window.location.reload();
                });
            });


            $(document).on('click', '.add-holiday', function(e){


                e.preventDefault();
                const $holidayModal = $('#holidayModal');

                if($holidayModal.length) {
                    $holidayModal.remove();
                }

                $('body').append("<div class='modal fade' id='holidayModal' tabindex='-1' role='dialog'><div class='modal-dialog' role='document'><div class='modal-content'><div class='modal-header'><h5 class='modal-title'>New holiday</h5><button type='button' class='close' data-dismiss='modal' aria-label='Close'><span aria-hidden='true'>&times;</span></button></div><div class='modal-body'><form><div class='form-group'><label for='holiday-day' class='col-form-label'>Holiday*</label><input type='text' name='holiday-day' id='holiday-day' class='datepicker form-control'></div><div class='form-group'><label class='col-form-label' for='holiday-name'>Name*</label><input type='text' class='form-control' name='holiday-name' id='holiday-name'></div><input type='hidden' name='holiday-type' value='user'></form></div><div class='modal-footer'><small class='text-muted'>* Required fields</small><button class='btn btn-primary holiday-save'><i class='fal fa-check'></i> Save</button><button class='btn btn-secondary' data-dismiss='modal'>Close</button></div></div></div></div>");

                $('#holidayModal').modal('show');

                $('input.datepicker').datepicker({
                    format: 'dd.mm.yyyy',
                    autoclose: true,
                    todayBtn: true,
                    todayHighlight: true,
                    weekStart: 1,
                    daysOfWeekHighlighted: [0,6],
                    maxViewMode: 2,
                });


            });

            $(document).on('click', '.holiday-save', function(e) {

                e.preventDefault();
                const $this = $(this),
                    $modalContent = $this.closest('.modal'),
                    $modalBody = $modalContent.find('.modal-body'),
                    $date = $('#holiday-day'),
                    date = $date.val(),
                    $name = $('#holiday-name'),
                    name = $name.val();

                $this.addClass('disabled');
                $this.attr('disabled', 'disabled');

                $modalBody.find('.alert').remove();

                if(name.length === 0 || moment(date, 'DD.MM.YYYY', true).isValid() === false) {
                    $modalBody.prepend("<div class='alert alert-danger'>The data is not valid.</div>");
                    $this.removeClass('disabled');
                    $this.removeAttr('disabled');
                    return false;
                }

                setBtnASSaving($this, 'fa-check');

                $.ajax({
                    url: '/backend/location/holidays/add-holiday',
                    type: 'post',
                    dataType: 'json',
                    data: {location: location, date: date, name: name}
                }).done(function(data) {
                    removeBtnSaving($this, 'fa-check');
                    if(data['success'] === false || data['data'] === false) {
                        $modalBody.prepend("<div class='alert alert-danger'>Saving failed.</div>");
                        $this.removeClass('disabled');
                        $this.removeAttr('disabled');
                        return false;
                    }

                    window.location.reload();

                });

            });


            $(document).on('click', '.popover-holiday-delete', function(e){
                e.preventDefault();
            });
            $(document).on('click', '.popover-close', function(e){
                e.preventDefault();
                $(document).find('[data-toggle="popover"]').popover('hide');
            });
            $(document).on('click', '.holiday-delete', function(e){
                e.preventDefault();
                const $this = $(this),
                    id = $this.data('id'),
                    $listItem = $('#holiday-' + id);

                $.ajax({
                    url: '/backend/location/holidays/delete',
                    type: 'post',
                    dataType: 'json',
                    data: {id: id}
                }).done(function(data){
                    if(data['success'] === false) {
                        return false;
                    }
                    $(document).find('[data-toggle="popover"]').popover('hide');
                    $listItem.fadeOut(250, function(){
                        $(this).remove();
                    });
                });
            });

        }


        // Export one day
        $(document).on('click', '.day.export-day', function(e){
            e.preventDefault();
            const $this = $(this),
                day = $this.data('day'),
                $header = $this.closest('.calendar-header'),
                month = $header.find('.month-title').data('month'),
                monthObj = moment(month, "YYYY-MM-DD").add(day - 1,'days');

            if($('#dayExportModal').length){
                $('#dayExportModal').remove();
            }

            $('body').append("<div class='modal fade' id='dayExportModal' tabindex='-1' role='dialog'><div class='modal-dialog' role='document'><div class='modal-content'><div class='modal-header'><h5 class='modal-title'>Send Day Report</h5><button type='button' class='close' data-dismiss='modal' aria-label='Close'><span aria-hidden='true'>&times;</span></button></div><div class='modal-body'><form><div class='form-group'><label for='holiday-day' class='col-form-label'>Date*</label><input type='text' name='date' id='date' class='datepicker form-control' value='" + monthObj.format("DD.MM.YYYY") + "' readonly></div><div class='form-group'><label for='export-email' class='col-form-label'>Email*</label><input type='email' name='email' id='export-email' class='form-control'></div></form></div><div class='modal-footer'><small class='text-muted'>* Required fields</small><button class='btn btn-primary day-export-btn'><i class='fal fa-check'></i> Save</button><button class='btn btn-secondary' data-dismiss='modal'>Close</button></div></div></div></div>");

            $('#dayExportModal').modal('show');

        });

        $(document).on('click', '.day-export-btn', function(e){
            e.preventDefault();
            const $this = $(this),
                $modal = $this.closest('.modal'),
                day = $modal.find('#date').val(),
                email = $modal.find('#export-email').val();

            $.ajax({
                url: '/backend/calendar/export-day',
                type: 'post',
                dataType: 'json',
                data: {day: day, email: email}
            }).done(function(data){
                if(data['success'] === false) {
                    return false;
                }
                $modal.modal('hide');
            });
        });


        $(document).on('click', '.day.is-backend', function(e){
            let $this = $(this);
            if($this.hasClass('fal')) {
                $this = $this.closest('.day.is-backend');
            }
            const attendanceId = $this.data('attendance'),
                targetDate = moment($this.data('date'), 'DD.MM.YYYY'),
                employeeId = $this.closest('.employee-calendar').data('id'),
                $employee = $('.employee.has-parking[data-id="' + employeeId + '"]'),
                type = attendanceId === undefined ? 'new' : 'info';
            parkingOwner = !!$employee.length;
            openModal(type, e.target, targetDate.format('DD.MM.YYYY'), employeeId);
        });

        // Activate navigation item, if an children in dropdown is active
        if($(".nav-item").hasClass("dropdown")){
            $(".nav-item.dropdown").each(function(){
                let $this = $(this),
                    $activeChild = $this.find(".active").first();
                if($activeChild.length > 0){
                    $this.find("a").first().addClass("active");
                }
            });
        }


        // Resend confirmation email
        let $resendBtn = $('.resend-confirmation');
        if($resendBtn.length > 0){
            $(document).on('click', '.resend-confirmation', function(e){
               e.preventDefault();
               let $this = $(this),
                   id = $this.data('id'),
                   iconCls = 'fa-redo';

               if($this.hasClass('saving')) {
                   return true;
               }

               $this.addClass('saving');
               setBtnASSaving($this, iconCls);

               $.ajax({
                   url: '/backend/employees/sendmail',
                   type: 'post',
                   dataType: 'json',
                   data: {id: id}
               }).done(function(data){
                   removeBtnSaving($this, iconCls);
                   if(data['success'] === true){
                       successTrueMsg('Resend confirmation email successful', true);
                       return true;
                   }

                   successTrueMsg('Resend confirmation email is failed', false);

               });

            });
        }


        // User edit form
        if($(".user-edit-form").length > 0){
            let $chPwd = $("#admin_changePwd");
            $chPwd.on("click", function(e){
                let chPwdChk = $chPwd.prop("checked"),
                    $pwd = $(".user-pwd");
                if(chPwdChk){
                    $pwd.removeClass("d-none");
                    $pwd.removeAttr("disabled");
                    $pwd.attr("required");
                } else {
                    $pwd.addClass("d-none");
                    $pwd.attr("disbaled", "disabled");
                    $pwd.removeAttr("required");
                }
            });
        }

        // Load user permissions
        if($("select#admins").length > 0){
            $("select#admins").on("change", function(){
                let $selected = $(this).find("option:selected"),
                    admin = $selected.val();
                if(admin === null){
                    return true;
                }
                window.location.href = Routing.generate("backend_users_permissions_edit", {admin: admin});
            });

            $(".permission-item > input[type='checkbox']").on("change", function(){
                let $this = $(this),
                    checked = $this.prop("checked"),
                    val = $this.val(),
                    admin = $(".permissions").data("admin");
                if(checked){
                    $.get(Routing.generate("backend_users_permissions_edit", {admin: admin, permission: val}));
                } else {
                    $.get(Routing.generate("backend_users_permissions_delete", {admin: admin, permission: val}));
                }
            });
        }

        // Employees

        // Search employees
        let employeeSearchField = $('.employee-search-field'),
            $employeeTable = $('.employee-table'),
            $tableWrapper = $employeeTable.closest('.row'),
            $oldTable = $tableWrapper.find('.employee-table').clone();
        if(employeeSearchField.length) {
            employeeSearchField.on('keyup', function(e){

                let $this = $(this),
                    $group = $this.closest('.input-group'),
                    $icon = $group.find('.fal'),
                    val = $this.val();

                if(val.length >= 3) {
                    $icon.removeClass('fa-search');
                    $icon.addClass('fa-spinner-third');
                    $.ajax({
                        url: "/backend/employees/search",
                        type: "post",
                        dataType: "json",
                        data: {search: val}
                    }).done(function(data){

                        if(data['success'] === false || data['count'] === 0) {
                            $icon.removeClass('fa-spinner-third');
                            $icon.addClass('fa-search');
                            $tableWrapper.html("<div class='col mt-3'><div class='alert alert-danger w-100'><i class='fal fa-exclamation-triangle'> Nothing found.</i></div></div>");
                        } else {

                            let $html = $($.parseHTML(data['data'])),
                                $table = $html.find('.employee-table');
                            $tableWrapper.html($table);
                            $icon.removeClass('fa-spinner-third');
                            $icon.addClass('fa-search');

                            getPrimaryLocation($table);
                            $table.find("[data-toggle='popover']").popover();
                        }
                    });

                } else {
                    $tableWrapper.html($oldTable);
                    $icon.removeClass('fa-spinner-third');
                    $icon.addClass('fa-search');
                }

            });
        }

        // Search for Employees in calendar (backend)
        let $calendarSearchField = $('.calendar-search-field'),
            $scheduleWrapper = $('.schedule-calendar').not('.home-office'),
            $employeeWrapper = $scheduleWrapper.find('.employee-wrapper'),
            $calWrapper = $scheduleWrapper.find('.calendar'),
            $oldEmployee = $scheduleWrapper.find('.employee-wrapper').clone(),
            $oldCalendar = $scheduleWrapper.find('.calendar').clone();

        if($calendarSearchField.length) {
            let wasHighVal = false;
            $calendarSearchField.on('keyup', function(e){
                let $this = $(this),
                    $group = $this.closest('.input-group'),
                    $icon = $group.find('.fal'),
                    val = $this.val();

                if(val.length >= 3) {
                    wasHighVal = true;
                    $icon.removeClass('fa-search');
                    $icon.addClass('fa-spinner-third');
                    setTimeout(function() {
                        $.ajax({
                            url: "/backend/calendar/search",
                            type: "post",
                            dataType: "json",
                            data: {search: val}
                        }).done(function (data) {

                            if (data['success'] === false) {
                                $icon.removeClass('fa-spinner-third');
                                $icon.addClass('fa-search');
                                $employeeWrapper.html("<div class='col mt-3'><div class='alert alert-danger w-100'><i class='fal fa-exclamation-triangle'> Nothing found.</i></div></div>");
                            } else {

                                let $html = $($.parseHTML(data['data'])),
                                    $wrapper = $html.find('.employee-wrapper').html(),
                                    $calendarWrapper = $html.find('.calendar').html();
                                $employeeWrapper.html($wrapper);
                                $calWrapper.html($calendarWrapper);
                                $icon.removeClass('fa-spinner-third');
                                $icon.addClass('fa-search');

                                getEmployeeCalendar(moment(month).format('YYYY'), moment(month).format('MM'));
                            }
                        });
                    }, 500);

                } else {
                    if(wasHighVal) {
                        $employeeWrapper.html($oldEmployee.html());
                        $calWrapper.html($oldCalendar.html());
                        getEmployeeCalendar(moment(month).format('YYYY'), moment(month).format('MM'));
                        wasHighVal = false;
                    }
                    $icon.removeClass('fa-spinner-third');
                    $icon.addClass('fa-search');
                }
            });
        }

        // Unlock locked employee (backend)
        if($employeeTable.length) {
            const $employeeLocked = $employeeTable.find('.employee-unlock');
            if($employeeLocked.length) {
                $(document).on('click', '.employee-unlock-no', function(e) {
                    e.preventDefault();
                    $("[data-toggle='popover']").popover('hide');
                });

                $(document).on('click', '.employee-unlock-yes', function(e) {
                    e.preventDefault();
                    const $this = $(this),
                        id = $this.data('id'),
                        $row = $("tr[data-employee='" + id + "']");
                    $this.find('.employee-status').removeClass('fa-check').removeClass('fa-exclamation-triangle').addClass('fa-spinner');
                    $.ajax({
                        url: '/backend/employees/unlock',
                        type: 'post',
                        dataType: 'json',
                        data: {id: id}
                    }).done(function(data) {
                        if(data['success'] === false) {
                            $this.find('.employee-status').removeClass('fa-spinner').addClass('fa-exclamation-triangle');
                            return false;
                        }
                        $row.removeClass('row-red');
                        $row.find('.status-cell .employee-status').popover('dispose');
                        $row.find('.status-cell .employee-status.fa-user-shield').replaceWith(
                            "<i class='fal fa-user-check employee-status mr-2' data-toggle='popover' data-placement='bottom' data-html='true' data-title='Block employee' data-content='The employee can no longer log in.<br><br><a href=\"#\" class=\"btn btn-danger btn-sm lock-employee-btn mr-1\" data-employee=\"" + id +  "\"><i class=\"fal fa-shield\"></i> Lock</a><a href=\"#\" class=\"btn btn-outline-secondary btn-sm disclaimer-cancel\">Cancel</a>'></i>"
                        );
                        $row.find('.status-cell .employee-status').popover();
                    });
                });
            }
        }


        // Multiple Locations
        // Select primary location
        if($employeeTable.length) {

            getPrimaryLocation($employeeTable);

            $(document).on('click', '.primary-location.btn', function(e) {
                e.preventDefault();

                const $this = $(this),
                    $row = $this.closest('tr'),
                    empl = $row.data('employee'),
                    id = $this.data('location');

                $this.find('.fal').remove();
                $this.removeClass('btn-outline-danger').addClass('btn-outline-primary');
                $this.prepend("<i class='fal fa-spinner'></i> ");
                $this.attr('disabled', 'disabled');

                $.ajax({
                    url: '/backend/employees/set-primary-location',
                    type: 'post',
                    dataType: 'json',
                    data: {'employee': empl, 'location': id}
                }).done(function(data) {
                    $this.find('.fa-spinner').remove();
                    $this.removeAttr('disabled');
                    if(data['success'] === false || data['data'] === false) {
                        $this.addClass('btn-outline-danger').removeClass('btn-outline-primary');
                        $this.prepend("<i class='fal fa-exclamation-triangle'></i> ");
                        return false;
                    }
                    $row.find('.primary-location.btn').removeClass('btn-primary').addClass('btn-outline-primary');
                    $row.find('.primary-location.btn .fal').remove();
                    $this.prepend("<i class='fal fa-check'></i> ");
                    $this.addClass('btn-primary').removeClass('btn-outline-primary');
                });
            });
        }

        // show only active or also inactive employees (backend)
        if($employeeTable.length) {
            const $onlyCheckbox = $('#only-active');
            if($onlyCheckbox.prop('checked')) {
                $employeeTable.find('tr.inactive').addClass('d-none');
            }

            $(document).on('change', '#only-active', function(e){
                if($(this).prop('checked')) {
                    $employeeTable.find('tr.inactive').addClass('d-none');
                } else {
                    $employeeTable.find('tr.inactive').removeClass('d-none');
                }
            });
        }


        if($(".employee-form").length > 0) {
            let $username = $("#employee_username"),
                $firstName = $("#employee_firstName"),
                $lastName = $("#employee_lastName");

            // gernerate username
            $(document).on("keyup", "#employee_firstName, #employee_lastName", function(e){
               let $this = e.target,
                   id = $this.id,
                   val = $($this).val().replace(/[\W_]/g, ''),
                   username = $username.val();
               if(id === "employee_firstName") {
                   username = val + "." + $lastName.val().replace(/[\W_]/g, '');
               } else if(id === "employee_lastName") {
                   username = $firstName.val().replace(/[\W_]/g, '') + "." + val;
               }
               $username.val(username.toLowerCase());
               return true;
            });


            // zones

            $(document).on('click', '.employee-zone-add-btn', function(e) {
                e.preventDefault();

                const employee = $(this).data('employee'),
                    zone = $(this).data('zone'),
                    location = $(this).closest('.modal').data('location');

                $.post("/backend/location/" + location + "/zone/employees/" + zone + "/add/" + employee, {is_employee_form: true})
                    .done(function(data){
                        if(data['success'] === false) {
                            return false;
                        }
                        const $table = $(".employee-zone-modal table"),
                            $zone_list = $(".zone-form-rows[data-id='" + location + "'] .zone-form-list");

                        $table.replaceWith(data['data']['modal']);
                        $zone_list.replaceWith(data['data']['list']);
                    });
            });


            // remove employee from zone
            $(document).on('click', '.employee-zone-remove-btn', function(e){
                e.preventDefault();
                const id = $(this).data('id'),
                    zone = $(this).data('zone'),
                    location = $(this).closest('.modal').data('location');

                $.post("/backend/location/" + location + "/zone/employees/" + zone + "/remove/" + id, {is_employee_form: true})
                    .done(function(data){
                        if(data['success'] === false) {
                            return false;
                        }
                        const $table = $(".employee-zone-modal table"),
                            $zone_list = $(".zone-form-rows[data-id='" + location + "'] .zone-form-list");

                        $table.replaceWith(data['data']['template']['modal']);
                        $zone_list.replaceWith(data['data']['template']['list']);
                    });

            });


            // add employee as responsible
            $(document).on('click', '.employee-zone-responsible-btn', function(e){
                e.preventDefault();
                const id = $(this).data('id'),
                    zone = $(this).data('zone'),
                    location = $(this).closest('.modal').data('location'),
                    $icon = $(this).find('.fa-user-crown');

                let is_admin = 0;

                if($icon.hasClass('fal')) {
                    is_admin = 1;
                }

                $.post("/backend/location/" + location + "/zone/employees/" + zone + "/edit/" + id, {is_employee_form: true, is_admin: is_admin})
                    .done(function(data){
                        if(data['success'] === false) {
                            return false;
                        }
                        const $table = $(".employee-zone-modal table"),
                            $zone_list = $(".zone-form-rows[data-id='" + location + "'] .zone-form-list");

                        $table.replaceWith(data['data']['template']['modal']);
                        $zone_list.replaceWith(data['data']['template']['list']);
                    });

            });


        }

        // Calendar
        if($calendarOverview.length > 0){
            let $wrapper = $calendarOverview.find('.calendar-wrapper');

            if($wrapper.length > 0){

                $('.employee').on('mouseenter mouseleave', function(e){
                   let $this = $(this),
                       id = $this.data('id');
                   if(e.type === 'mouseleave'){
                       $('.employee, .employee-calendar').css({'opacity': 1});
                       return true;
                   }
                   $('.employee, .employee-calendar').css({'opacity': .5});
                   $this.css({'opacity': 1});
                   $('.employee-calendar[data-id="' + id + '"]').css({'opacity': 1});

                });

                $('.employee-calendar').on('mouseenter mouseleave', function(e){
                    let $this = $(this),
                        id = $this.data('id');
                    if(e.type === 'mouseleave'){
                        $('.employee-calendar, .employee').css({'opacity': 1});
                        return true;
                    }
                    $('.employee-calendar, .employee').css({'opacity': .5});
                    $('.employee[data-id="' + id + '"]').css({'opacity': 1});
                    $this.css({'opacity': 1});
                });

                getEmployeeCalendarHeader(month);
                getEmployeeCalendar(moment(month).format('YYYY'), moment(month).format('MM'));

                $(document).on('click', '.calendar-header .month-title', function(e){
                    let $this = $(this),
                        $header = $this.closest('.calendar-header'),
                        currentDate = $this.data('month');
                    $this.datepicker({
                        startView: 1,
                        defaultViewDate: moment(currentDate, 'YYYY-MM-DD').format('DD.MM.YYYY'),
                        minViewMode: 1,
                        maxViewMode: 2,
                        format: 'dd.mm.yyyy',
                        autoclose: true,
                        todayBtn: false,
                        todayHighlight: true,
                        disableTouchKeyboard: true,
                        weekStart: 1,
                        zIndexOffset: 2000
                    });
                    $this.datepicker('show');
                    $this.datepicker().on('changeMonth', function(e){
                        const date = moment(e.date);
                        $('.employee-calendar').fadeOut(100, function(){
                            $(this).html('').css({'display': 'flex'});
                        });
                        getEmployeeCalendarHeader(date);
                        getEmployeeCalendar(date.format('YYYY'), date.format('MM'));
                        if($header.hasClass('attendance-export')) {
                            changeExportURL();
                        }
                    });
                });
            }
        }


        // Datepicker
        $(".datepicker").datepicker({
            format: 'dd.mm.yyyy',
            startDate: '-70y',
            autoclose: false,
            todayBtn: true,
            todayHighlight: true,
            weekStart: 1,
            daysOfWeekHighlighted: [0,6]
        });


        const $surveyTable = $('.survey-table');
        if($surveyTable.length) {
            let $surveys = $surveyTable.find('tbody tr');

            // start or stop survey
            $(document).on('click', '.stop-survey, .start-survey', function(e) {
                e.preventDefault();
                const $this = $(this),
                    id = $this.closest('tr').data('id');
                let status = $this.hasClass('start-survey') ? 1 : 0;

                $.ajax({
                    url: '/backend/survey/status',
                    type: 'post',
                    dataType: 'json',
                    data: {survey: id, status: status}
                }).done(function(data){
                    if(data['success'] === true && data['data'] === true) {
                        if(status === 1) {
                            $this.removeClass('start-survey').addClass('stop-survey');
                            $this.find('.fas').removeClass('fa-play').addClass('fa-stop');
                        } else {
                            $this.removeClass('stop-survey').addClass('start-survey');
                            $this.find('.fas').removeClass('fa-stop').addClass('fa-play');
                        }
                    }
                });

            });


            // count results
            $surveys.each(function(k, v){
                const $this = $(this),
                    $data_options = $this.find('.btn-group.data-options'),
                    $count = $this.find('.survey-respondents'),
                    id = $this.data('id');

                $count.find('.text-danger').remove();
                $count.html("<i class='fal fa-spinner-third'></i>");

                if($data_options.length && parseInt($this.data('status')) === 1) {
                    $data_options.find('.btn:not(.btn-outline-danger)').each(function (k, v) {
                        $(this).addClass('disabled');
                    });
                }

                $.ajax({
                    url: "/backend/survey/countresults",
                    async: true,
                    type: 'post',
                    dataType: 'json',
                    data: {id: id}
                }).done(function(data){
                    if(data['success'] === false || data['data'] === false) {
                        $count.html("<span class='text-danger'><i class='fal fa-exclamation-triangle'></i> Error</span>");
                    }
                    if(data['data'][1] > 0) {
                        $count.html(data['data'][1] + " <a href='#' class='btn btn-outline-secondary btn-sm clear-respondents ml-3'><i class='fal fa-times'></i> Clear</a>");
                    } else {
                        $count.text(data['data'][1]);
                    }
                    if($data_options.length) {
                        if(parseInt(data['data'][1]) > 0) {
                            $data_options.find('.btn:not(.btn-outline-danger)').each(function (k, v) {
                                $(this).addClass('disabled');
                            });
                        } else {
                            $data_options.find('.btn').each(function (k, v) {
                                $(this).removeClass('disabled');
                            });
                        }
                    }
                });
            });


            $(document).on('click', '.clear-respondents', function(e){
                e.preventDefault();

                const $survey = $(this).closest('tr'),
                    survey_id = $survey.data('id');

                let $modal = "<div class='modal fade' id='clearRespondents" + survey_id +"' tabindex='-1' role='dialog' aria-labelledby='clearRespondentsLabel" + survey_id + "' aria-hidden='true'>\n" +
                    "<div class='modal-dialog' role='document'>\n" +
                    "   <div class='modal-content'>\n" +
                    "       <div class='modal-header'>\n" +
                    "           <h5 class='modal-title' id='clearRespondentsLabel" + survey_id + "'>Clear results of the survey</h5>\n" +
                    "           <button type='button' class='close' data-dismiss='modal' aria-label='Close'>\n" +
                    "               <span aria-hidden='true'>&times;</span>\n" +
                    "           </button>\n" +
                    "       </div>\n" +
                    "       <div class='modal-body'>\n" +
                    "           Do you want to clear the results of this survey?<br>After that you are able to change settings of this survey.\n" +
                    "       </div>\n" +
                    "       <div class='modal-footer'>\n" +
                    "           <a href='#' type='button' class='btn btn-outline-danger clear-respondents-yes' data-id='" + survey_id + "'>Yes, clear</a> <button type='button' class='btn btn-outline-secondary' data-dismiss='modal'>No</button>\n" +
                    "       </div>\n" +
                    "   </div>\n" +
                    "</div>";

                $(this).after($modal);
                $('#clearRespondents' + survey_id).modal('show');
            });


            $(document).on('click', '.clear-respondents-yes', function(e) {
                e.preventDefault();
                const $this = $(this),
                    $modal = $this.closest('.modal'),
                    id = $this.data('id');

                if(id === null || id === '') {
                    return false;
                }

                $this.prepend("<i class='fal fa-spinner-third'></i> ");
                $modal.find('.response').remove();

                $.ajax({
                    url: '/backend/survey/wizard/clear-respondents',
                    type: 'post',
                    dataType: 'json',
                    data: {id: id}
                }).done(function(data) {
                    $this.find('.fal').remove();
                    if(data['success'] === false) {
                        $modal.find('.modal-body').prepend("<p class='alert alert-danger response'><strong>Error</strong> Saving failed!</p>");
                        return false;
                    }

                    $('#clearRespondents' + id).modal('hide');

                });

                $('#clearRespondents' + id).on('hidden.bs.modal', function(e){
                    $(this).modal('dispose');
                    const $row = $('tr[data-id="' + id + '"');
                    $row.find('.survey-respondents').text('0');
                    $row.find('.btn.disabled').removeClass('disabled');
                });

            });
        }

    }










    // Frontend (Candidates)
    if($(".candidates").length > 0){
        $(".datepicker").datepicker({
            format: 'dd.mm.yyyy',
            startDate: '+1d',
            autoclose: false,
            todayBtn: true,
            todayHighlight: true,
            weekStart: 1,
            daysOfWeekHighlighted: [0,6]
        });
    }

    // Frontend Employees
    const $bEmployee = $("body.employee"),
        $backendAttendance = $("body.backend .attendances");
    if($bEmployee.length){

        $(document).on('show.bs.modal', function (e) {
            if($(e.target).hasClass("modal") && $("body").hasClass("frontend")) {
                $('.navbar, section.container-fluid, footer').addClass('blur');
            }
        });
        $(document).on('hide.bs.modal', function (e) {
            if(!$(e.target).hasClass('datepicker')) {
                $('.navbar, section.container-fluid, footer').removeClass('blur');
            }
        });

        let getEmployee = function(employee){
            getCalendarEvents(employee);
        };


        // Calendar for attendances
        if($('#calendar').length > 0 && $('#template-calendar').length > 0 && !$employeeAttendanceSelect.length) {
            getCalendarEvents();
        }

        if($employeeAttendanceSelect.length > 0){

            $(function() {
                let employee = $employeeAttendanceSelect.val();
                getEmployee(employee);
            });

            $employeeAttendanceSelect.on('change', function(e){
                e.preventDefault();
                $calendar.clndr().destroy();
                let $this = $(this),
                    val = $this.val();

                getEmployee(val);
            });
        }

        // Chart for attendances
        getChartData($chartWrapper, 'week');
        getChartData($chartMonthWrapper, 'month');

        // Get Dashboard calendar
        getDashboardCalendar();

        // Get data for dashbaord calendar
        getDashboardData();



        // Calendar overview
        if($calendarOverview.length > 0){
            let $wrapper = $calendarOverview.find('.calendar-wrapper');

            if($wrapper.length > 0){

                $('.employee-wrapper .employee').on('mouseenter mouseleave', function(e){
                    let $this = $(this),
                        id = $this.data('id');
                    if(e.type === 'mouseleave'){
                        $('.employee-wrapper .employee, .employee-calendar').css({'opacity': 1});
                        return true;
                    }
                    $('.employee-wrapper .employee, .employee-calendar').css({'opacity': .5});
                    $this.css({'opacity': 1});
                    $('.employee-calendar[data-id="' + id + '"]').css({'opacity': 1});

                });

                $('.employee-calendar').on('mouseenter mouseleave', function(e){
                    let $this = $(this),
                        id = $this.data('id');
                    if(e.type === 'mouseleave'){
                        $('.employee-calendar, .employee-wrapper .employee').css({'opacity': 1});
                        return true;
                    }
                    $('.employee-calendar, .employee-wrapper .employee').css({'opacity': .5});
                    $('.employee[data-id="' + id + '"]').css({'opacity': 1});
                    $this.css({'opacity': 1});
                });

                getEmployeeCalendarHeader(month);
                getEmployeeCalendar(moment(month).format('YYYY'), moment(month).format('MM'));

                $(document).on('click', '.calendar-header .month-title', function(e){
                    let $this = $(this),
                        $header = $this.closest('.calendar-header'),
                        currentDate = $this.data('month');
                    $this.datepicker({
                        startView: 1,
                        defaultViewDate: moment(currentDate, 'YYYY-MM-DD').format('DD.MM.YYYY'),
                        minViewMode: 1,
                        maxViewMode: 2,
                        format: 'dd.mm.yyyy',
                        autoclose: true,
                        todayBtn: false,
                        todayHighlight: true,
                        disableTouchKeyboard: true,
                        weekStart: 1,
                        zIndexOffset: 2000
                    });
                    $this.datepicker('show');
                    $this.datepicker().on('changeMonth', function(e){
                        const date = moment(e.date);
                        $('.employee-calendar').fadeOut(100, function(){
                            $(this).html('').css({'display': 'flex'});
                        });
                        getEmployeeCalendarHeader(date);
                        getEmployeeCalendar(date.format('YYYY'), date.format('MM'));
                        if($header.hasClass('attendance-export')) {
                            changeExportURL();
                        }
                    });
                });
            }


            // Team filter

            $(document).on('click', '.team-filter', function(e) {
                e.preventDefault();
                const $this = $(this),
                    $parent = $this.closest('.dropdown'),
                    $button = $parent.find('button'),
                    teamId = $this.data('id');

                filterTeamEmployeeCalendar(teamId, $button);
            });

            // Cancel team filter
            $(document).on('click', '.calendar-cancel-filter', function(e) {
                e.preventDefault();

                const $this = $(this),
                    $filterBtn = $($this.data('filter')),
                    $calendar = $('.schedule-calendar').not('.home-office'),
                    $results = $this.closest('.calendar-filter-results');

                if($filterBtn.length === 0) {
                    return false;
                }

                $results.remove();
                $filterBtn.removeClass('active');
                $calendar.find('.employee.d-none').removeClass('d-none').addClass('d-flex');
                $calendar.find('.employee-calendar').removeClass('d-none');

                window.location.hash = '';

                changeExportURL();2

            });

            // Zone filter

            $(document).on('click', '.zone-filter', function(e) {
                e.preventDefault();
                const $this = $(this),
                    $parent = $this.closest('.dropdown'),
                    $button = $parent.find('button'),
                    zoneId = $this.data('id');

                filterZoneEmployeeCalendar(zoneId, $button);
            });
        }



        // Account


        // Employee data
        if($('.employee-form').length) {
            const changePwdBtn = $('#edit-employee-password');
            $(document).on('click', '#edit-employee-password', function(e){
               const $modal = $('#passwordEdit'),
                $modalBody = $modal.find('.modal-body');
                $modal.modal({
                    show: true
                });

                async function fetchContent() {
                    const response = await fetch("/employee/account/get-pwd-edit-form", {
                        method: 'GET'
                    });
                    return response;
                };

                fetchContent().then((response) => {
                    if(!response.ok) {
                        throw new Error('Error');
                    }
                    return response.json();
                }).then((result) => {
                    if(result.success !== true) {
                        return;
                    }
                    $modalBody.append(result.data.template);
                    getTogglePwdElements($modalBody);

                    return;
                }).then(()=> {
                    let pwdChangeForm = document.getElementById('pwd-edit-form');

                    pwdChangeForm.addEventListener('submit', function (e) {
                        e.preventDefault();
                        const data = new FormData(pwdChangeForm);

                        async function postData() {
                            const response = await fetch('/employee/pwd-change', {
                                method: 'POST',
                                body: data
                            });
                            return response;
                        };

                        postData().then((response) => {
                            if (!response.ok) {
                                throw new Error('PasswortChangeError');
                            }
                            return response.json();
                        }).then((response) => {
                            const errorElem = pwdChangeForm.querySelector('.alert.alert-danger');
                            if (errorElem !== null) {
                                errorElem.remove();
                            }
                            if (response.success !== true) {
                                let errTxt = "";
                                if (response.error) {
                                    response.error.forEach(err => errTxt += err + "<br>");
                                }
                                pwdChangeForm.insertAdjacentHTML('afterbegin', '<div class="alert alert-danger" role="alert">' + errTxt + '</div>');
                                return;
                            }

                            pwdChangeForm.remove();
                            $modalBody.append('<div class="alert alert-success">Your new password saved successfully!</div>');

                            window.setTimeout(function () {
                                $modal.modal('hide');
                            }, 3000);

                        });

                    });
                });

                $modal.on('hidden.bs.modal', function(event) {
                    $modalBody.find('form').remove();
                    $modalBody.find('.alert').remove();
                });
            });

        }


        // Teams
        const $teamModal = $('#team-modal'),
            $teamModalBody = $teamModal.find('.modal-body'),
            getEmployees = function(teamId) {
                $teamModalBody.append("<div class='team-employees mt-5'><i class='fal fa-spinner-third'></i></div>");
                $.ajax({
                    url: '/employee/account/teams/getEmployees',
                    type: 'post',
                    dataType: 'json',
                    data: {team: teamId}
                }).done(function(data){
                    if(data['success']) {
                        const employeesMyLoc = data['data']['employeesMyLoc'],
                            employeesOther = data['data']['employeesOther'],
                            $employeeList = $(document).find('.team-employees');

                        const saveBtn = "<a href='#' class='btn btn-primary position-absolute team-save-btn' style='right:1rem;'><i class='fal fa-check'></i> Save</a>";

                        let list = saveBtn + "<ul class='nav nav-tabs' id='team-tabs' role='tablist'><li class='nav-item'><a class='nav-link active' id='team-my-loc-tab' data-toggle='tab' href='#team-my-loc' role='tab'>My Location</a></li><li class='nav-item'><a class='nav-link' id='team-other-tab' data-toggle='tab' href='#team-other' role='tab'>Other</a></li></ul>";

                        list += "<div class='tab-content' id='team-tabs-content'><div id='team-my-loc' class='tab-pane team-tab-pane show fade active' role='tabpanel'><ul class='list-group list-group-flush'>";


                        $.each(employeesMyLoc, function(k, v){
                            let starFaCls = 'fal',
                                starCls = 'fa-star',
                                disclaimerCls = '',
                                tooltip = '',
                                mutedCls = '';
                            const teamExists = v['teams'].length ? v['teams'].filter(t => t.id === teamId) : [];
                            if(v['disclaimerAccepted'] === false) {
                                disclaimerCls = '<i class="fal fa-exclamation-triangle"></i> ';
                                mutedCls = ' text-muted';
                                starCls = '';
                                tooltip = ' data-container="#team-modal" data-toggle="tooltip" data-placement="top" title="Terms of use not accepted"';
                            }
                            if(v['teams'].length && teamExists.length) {
                                starFaCls = 'fas';
                                starCls = 'fa-star';
                            }

                            list += "<li class='list-group-item'><a href='#' class='d-block" + mutedCls + "' data-team='" + teamId + "' data-employee='" + v['id'] + "'" + tooltip + ">" + disclaimerCls + "<span class='text-uppercase'>" + v['lastName'] + "</span> " + v['firstName'] + " <span class='float-right'><i class='" + starFaCls + " " + starCls + "'></i></span></a></li>";
                        });



                        list += "</ul></div>";

                        list += "<div id='team-other' class='tab-pane team-tab-pane fade' role='tabpanel'><ul class='list-group list-group-flush'>";

                        $.each(employeesOther, function(k, v){

                            let starFaCls = 'fal',
                                starCls = 'fa-star',
                                disclaimerCls = '',
                                tooltip = '',
                                mutedCls = '';
                            const teamExists = v['teams'].length ? v['teams'].filter(t => t.id === teamId) : [];
                            if(v['disclaimerAccepted'] === false) {
                                disclaimerCls = '<i class="fal fa-exclamation-triangle"></i> ';
                                mutedCls = ' text-muted';
                                starCls = '';
                                tooltip = ' data-container="#team-modal" data-toggle="tooltip" data-placement="top" title="Terms of use not accepted"';
                            }
                            if(v['teams'].length && teamExists.length) {
                                starFaCls = 'fas';
                                starCls = 'fa-star';
                            }

                            list += "<li class='list-group-item'><a href='#' class='d-block" + mutedCls + "' data-team='" + teamId + "' data-employee='" + v['id'] + "'" + tooltip + ">" + disclaimerCls + "<span class='text-uppercase'>" + v['lastName'] + "</span> " + v['firstName'] + " <span class='float-right'><i class='" + starFaCls + " " + starCls + "'></i></span></a></li>";
                        });

                        list += "</ul></div>";

                        list += "</div>";

                        $employeeList.html(list);

                        $('[data-toggle="tooltip"]').tooltip();
                    }
                });
            };


        if($teamModal.length) {

            $(document).on('click', '.team-save-btn', function(e){
                $teamModal.modal('hide');
            });

            $(document).on('click', '.team-add-btn', function(e){
                e.preventDefault();
                $teamModalBody.html(" ");
                const $this = $(this),
                    type = $this.data('type'),
                    teamId = $this.data('team');
                let html = "",
                    btnHtml = teamId === 0 ? "<i class='fal fa-check'></i> Save" : "<i class='fal fa-check'></i> Edit",
                    teamTitle = "";

                if(type !== 'new') {
                    $.ajax({
                        url: '/employee/account/teams/getTeam',
                        type: 'post',
                        dataType: 'json',
                        data: {id: teamId}
                    }).done(function(data) {
                        if(data['success'] === false) {
                            return false;
                        }
                        teamTitle = data['data']['title'];
                        $(document).find('#team-name').val(teamTitle);
                        getEmployees(teamId);
                    });
                }

                html += "<div class='form-group'><label for='team-name'>Title*</label><div class='input-group'><input type='text' id='team-name' class='form-control' value='" + teamTitle + "'><div class='input-group-append'><button class='btn btn-outline-primary team-title-save' data-team='" + teamId + "'>" + btnHtml + "</button></div><small class='form-text text-muted'>Give your team a title. After that, you can add colleagues to your team.</small></div></div>";

                $teamModalBody.html(html);

                //getEmployees(teamId);

            });

            $(document).on('click', '.team-title-save', function(e) {
                const $this = $(this),
                    $icon = $this.find('.fal'),
                    $input = $this.closest('.input-group').find('input'),
                    teamTitle = $input.val(),
                    teamId = $this.data('team');

                $icon.removeClass('fa-check');
                $icon.addClass('fa-spinner-third');

                $input.removeClass('is-invalid');

                if(teamTitle.length) {

                    $.ajax({
                        url: '/employee/account/teams/setTeam',
                        type: 'post',
                        dataType: 'json',
                        data: {title: teamTitle, id: teamId}
                    }).done(function(data){
                        $icon.removeClass('fa-spinner-third');
                        if(data['success'] === false) {
                            $icon.addClass('fa-times');
                            return false;
                        }
                        $icon.addClass('fa-check');
                        $this.attr('data-team', data['data']);
                        $this.data('team', data['data']);
                        $this.html("<i class='fal fa-check'></i> Edit");

                        getEmployees(data['data']);
                    });

                } else {
                    $input.addClass('is-invalid');
                    $icon.addClass('fa-check');
                    $icon.removeClass('fa-spinner-third');
                }
            });


            $(document).on('click', '.team-employees .list-group-item a', function(e) {
                e.preventDefault();

                const $this = $(this),
                    employeeId = $this.data('employee'),
                    teamId = $this.data('team'),
                    $star = $this.find('.fa-star'),
                    type = $star.hasClass('fal') ? 'add' : 'remove';

                if($star.length === 0) {
                    return false;
                }

                if($this.hasClass('text-muted') && type === 'add'){
                    return false;
                }

                $this.addClass('disabled');

                $.ajax({
                    url: '/employee/account/teams/setEmployee',
                    type: 'post',
                    dataType: 'json',
                    data: {id: employeeId, team: teamId, type: type}
                }).done(function(data){
                    $this.removeClass('disabled');
                    if(data['success'] === false) {
                        $this.addClass('text-danger');
                        return false;
                    }

                    if(type === 'remove') {
                        $star.removeClass('fas');
                        $star.addClass('fal');
                    } else {
                        $star.removeClass('fal');
                        $star.addClass('fas');
                    }

                });
            });


            $(document).on('click', '.team-delete', function(e) {
                e.preventDefault();
                const $this = $(this),
                    teamId = $this.data('team');
                $.ajax({
                    url: '/employee/account/teams/deleteTeam',
                    type: 'post',
                    dataType: 'json',
                    data: {team: teamId}
                }).done(function(data) {
                    if(data['success'] === false) {
                        return false;
                    }
                    $(document).find('[data-toggle="popover"]').popover('hide');
                    $('#team-' + teamId).fadeOut(250, function(){
                        $(this).remove();
                    });
                });

            });


            $(document).on('click', '.team-employee-remove', function(e) {
                e.preventDefault();
                const $this = $(this),
                    employeeId = $this.data('employee'),
                    teamId = $this.data('team'),
                    $listItem = $('#member-' + employeeId + '-' + teamId);

                $.ajax({
                    url: '/employee/account/teams/setEmployee',
                    type: 'post',
                    dataType: 'json',
                    data: {id: employeeId, team: teamId, type: 'remove'}
                }).done(function(data) {
                    if(data['success'] === false) {
                        return false;
                    }
                    $(document).find('[data-toggle="popover"]').popover('hide');
                    $listItem.fadeOut(250, function(){
                        $(this).remove();
                    });
                });
            });

            $(document).on('click', '.popover-close', function(e) {
                e.preventDefault();
                $(document).find('[data-toggle="popover"]').popover('hide');
            });

            $('#team-modal').on('hidden.bs.modal', function(e) {
                window.location.reload();
            });

            $('#team-modal').on('shown.bs.modal', function(e) {
                $('[data-toggle="tooltip"]').tooltip();
            });
        }

        // Representative
        const $repForm = $('.representative-form'),
            $repList = $('.representative-list'),
            $delDialog = $('#representative-del-dialog');
        let getRepresentatives = function() {
                $.ajax({
                    url: '/employee/account/getrepresentative',
                    type: 'post',
                    datatType: 'json'
                }).done(function(data){
                    if(data['success'] === true){
                        let representatives = data['data'],
                            html = '';

                        document.querySelector('.representative-form').style.removeProperty('display');
                        if(representatives.length >= 1) {
                            document.querySelector('.representative-form').style.setProperty('display', 'none', 'important');
                        } 

                        html = "<div class='row'><div class='col-12'><table class='table'><thead class='thead-sm'><tr><th>Name</th><th>Status</th><th>Operations</th></tr></thead><tbody>";
                        $.each(representatives, function(k, item) {
                            let statusCls = 'fa-hourglass-start',
                                operations = '';

                            if(item['status'] === 1) {
                                statusCls = 'fa-user-check';
                            } else if (item['status'] === 0) {
                                statusCls = 'fa-user-slash';
                                operations = " <a href='#' data-id='" + item['id'] + "' class='btn btn-primary btn-icon btn-sm resend-representative ml-1'><i class='fal fa-redo'></i><span class='d-none d-lg-inline'> Resend</span></a>";
                            }
                            html += "<tr><td>" + item['firstName'] + " <span class='text-uppercase'>" + item['lastName'] + "</span></td><td><i class='fal " + statusCls + "'></i></td><td><a href='#' data-id='" + item['id'] + "' class='btn btn-primary btn-icon btn-sm delete-representative'><i class='fal fa-trash-alt'></i><span class='d-none d-lg-inline'> Delete</span>" + operations + "</a></td></tr>";
                        });
                        html += "</tbody></table></div></div>";

                        $repList.html(html);
                    }
                });
            };
        if($repList.length > 0){

            getRepresentatives();

            // set representative
            $(document).on('click', '.save-representative', function(e){
               e.preventDefault();

               const $this = $(this),
                   representative = $repForm.find('.representative').val();

               if(representative > 0){
                   $.ajax({
                       url: '/employee/account/set-representative',
                       type: 'post',
                       dataType: 'json',
                       data: {id: representative}
                   }).done(function (data) {
                       if(data['success'] === true){
                           window.location.reload();
                       }
                   })
               }

            });

            // dialog delete representative
            $(document).on('click', '.delete-representative', function(e) {
                e.preventDefault();

                const $this = $(this),
                    id = $this.data('id');

                $delDialog.html("");
                $.ajax({
                    url: '/employee/account/getrepresentative',
                    type: 'post',
                    dataType: 'json',
                    data: {id: id}
                }).done(function (data) {
                    $delDialog.html("<div class='modal-dialog'><div class='modal-content'><div class='modal-header'><h5 class='modal-title subline'>Delete representative</h5><button type='button' data-dismiss='modal' class='close'><span>&times;</span></button></div><div class='modal-body'><div class='container-fluid px-0'><div class='row'><div class='col'>Do you really want that <strong>" + data['data'][0]['firstName'] + " <span class='text-uppercase'>" + data['data'][0]['lastName'] + "</span></strong> is no longer your representative?</div></div><div class='row mt-3'><div class='col'><hr class='mb-3'><button type='button' class='btn btn-danger representative-delete-yes' data-id='" + id + "'>Yes, delete</button> <button type='button' class='btn btn-light' data-dismiss='modal'>No</button></div></div></div></div></div></div>");
                    $delDialog.modal('show');
                });

            });


            // Resend representative
            $(document).on('click', '.resend-representative', function(e){
                e.preventDefault();
                let $this = $(this),
                    id = $this.data('id');

                $('.resending-failed').remove();

                $.ajax({
                    url: '/employee/account/resend-representative',
                    type: 'post',
                    dataType: 'json',
                    data: {id: id}
                }).done(function(data){
                    if(data['success'] === false) {
                        $this.after(" <button type='button' class='resending-failed btn btn-sm btn-outline-danger' data-toggle='tooltip' data-placement='top' title='Resending failed!'><i class='fal fa-exclamation-triangle'></i>");

                        $('[data-toggle="tooltip"]').tooltip();
                        return false;
                    }

                    if(data['success'] === true) {
                        getRepresentatives();
                        return true;
                    }

                });

            });


            // Delete representative
            $(document).on('click', '.representative-delete-yes', function(e){

                e.preventDefault();

                let $this = $(this),
                    id = $this.data('id');

                $delDialog.find('.alert').remove();

                $.ajax({
                    url: '/employee/account/delete-representative',
                    type: 'post',
                    dataType: 'json',
                    data: {id: id}
                }).done(function(data){
                    if(data['success'] === true) {
                        $delDialog.modal('hide');
                        getRepresentatives();
                        return true;
                    } else {
                        $delDialog.find('.modal-body').prepend("<div class='alert alert-danger col-12'>Deleting failed!</div>");
                    }
                });

            });

        }




        // Disclaimer
        if($('#disclaimerAcception').length > 0){
            $('#disclaimerAcception').modal({
                show: true,
                backdrop: 'static'
            });

            $(document).on('click', '#disclaimerAcception .yes-accept', function(e){
                e.preventDefault();
                let $this = $(this),
                    $form = $this.closest('form'),
                    $chk = $form.find('.agree-disclaimer');

                $form.find('.alert-danger').remove();

                if(false === $chk.prop('checked')){
                    $form.prepend("<div class='alert alert-danger'>Please select the checkbox.</div>");
                    return true;
                }

                $.ajax({
                    url: '/employee/legal-discalimer/accepted',
                    type: 'post',
                    dataType: 'json',
                    data: { accepted: true }
                }).done(function(data){
                    if(data['success'] === false){
                        $form.prepend("<div class='alert alert-danger'><strong>Saving failed.</strong> Please try again later.</div>");
                        return true;
                    }
                    clearForm($form);
                    $('#disclaimerAcception').modal('hide');
                });

            });

            $(document).on('click', '#disclaimerAcception .no-accept', function(e){
                e.preventDefault();
                let $this = $(this),
                    $form = $this.closest('form');

                $form.find('.alert-danger').remove();

                $.ajax({
                    url: '/employee/legal-discalimer/accepted',
                    type: 'post',
                    dataType: 'json',
                    data: { accepted: false }
                }).done(function(data){
                    if(data['success'] === false){
                        $form.prepend("<div class='alert alert-danger'><strong>Saving failed.</strong> Please try again later.</div>");
                        return true;
                    }
                    clearForm($form);
                    $('#disclaimerAcception').modal('hide');
                });
            });
        }



        // Change password
        if($('#passwordChange').length > 0){
            const $modal = $('#passwordChange'),
                $modalBody = $modal.find('.modal-body');
            $modal.modal({
                show: true,
                backdrop: 'static'
            });

            async function fetchContent() {
                const response = await fetch("/employee/get-pwd-change-form", {
                    method: 'GET'
                });
                return response;
            };

            fetchContent().then((response) => {
                if(!response.ok) {
                    throw new Error('Error');
                }
                return response.json();
            }).then((result) => {
                if(result.success !== true) {
                    return;
                }
                $modalBody.append(result.data.template);
                getTogglePwdElements($modalBody);
                return;
            }).then(()=> {
                let pwdChangeForm = document.getElementById('pwd-change-form');

                pwdChangeForm.addEventListener('submit', function(e) {
                    e.preventDefault();
                    const data = new FormData(pwdChangeForm);

                    async function postData(){
                        const response = await fetch('/employee/pwd-change', {
                            method: 'POST',
                            body: data
                        });
                        return response;
                    };

                    postData().then((response) => {
                        if(!response.ok) {
                            throw new Error('PasswortChangeError');
                        }
                        return response.json();
                    }).then((response) => {
                        const errorElem = pwdChangeForm.querySelector('.alert.alert-danger');
                        if(errorElem !== null) {
                            errorElem.remove();
                        }
                        if(response.success !== true) {
                            let errTxt = "";
                            if(response.error) {
                                response.error.forEach(err => errTxt += err + "<br>");
                            }
                            pwdChangeForm.insertAdjacentHTML('afterbegin', '<div class="alert alert-danger" role="alert">' + errTxt + '</div>');
                            return;
                        }

                        pwdChangeForm.remove();
                        $modalBody.append('<div class="alert alert-success">Your new password saved successfully!</div>');

                        window.setTimeout(function(){
                            $modal.modal('hide');
                        }, 3000);

                    });

                });
            });
        }


        // Forgot password
        let $pwdModal = $("#forgetPwd");
        if($pwdModal.length > 0){

            let $resetBtn = $(".generate-new-pwd");

            $('.forget-pwd-open-modal').on('click', function(e){
               let $form = $pwdModal.find('form').first();
               clearForm($form);
            });

            $(document).on('click', '.generate-new-pwd', function(e){
                e.preventDefault();

                let username = $("#forgetpwd-username").val(),
                    email = $("#forgetpwd-email").val(),
                    $this = $(this),
                    $form = $this.closest('form');

                $form.find('.alert').remove();

                $this.find('.fal').removeClass('fa-check').addClass('fa-spinner');

                if($.trim(username) === '' || $.trim(email) === '' || username === null || email === null){
                    $this.find('.fal').removeClass('fa-spinner').addClass('fa-check');
                    $form.prepend("<div class='alert alert-danger'>Please enter username and email to reset your password.</div>");
                    return true;
                }

                $.ajax({
                   url: '/employee/reset-pwd',
                   type: 'post',
                   dataType: 'json',
                   data: { username: username, email: email }
                }).done(function(data){
                    $this.find('.fal').removeClass('fa-spinner').addClass('fa-check');
                    if(data['success'] === false){
                        $form.prepend("<div class='alert alert-danger'>" + data['data'] + "</div>");
                        return true;
                    }
                    $form.before("<div class='alert alert-success'>Your password has been reseted.<br>We have send you an email with your new login information.</div>");
                    $form.empty();
                });
            });


        }
    }


    // General functions

    // enable popovers
    $('[data-toggle="popover"]').popover();

    (function($){
        $.fn.cryptMail = function(at, point, addClass, title)
        {
            /* Regular Expressions fuer @ und . definieren
         * falls die Argumente gesetzt sind
         **/

            var at = at && new RegExp(at, 'g') || false, point = point && new RegExp(point, 'g') || false;

            /* Plugin-Funktion ausfuehren, falls
             * Regular Expressions fuer @ und . definiert
             * sind.
             * Ansonsten nur 'this' zurueck geben.
             **/

            return at && point && this.each(
                function()
                {
                    /* Wenn 'addClass' auf 'true' gesetzt ist,
                     * dem Element die Klasse 'js' anhaengen
                     **/

                    this.className = this.className + ( addClass && ' js' || '');

                    /* Verschleierte Adresse anhand der Leer-
                     * zeichen in Array aufsplitten
                     **/

                    var mailTo = this.innerHTML.split(' '),
                        i, mailToLen = mailTo.length,
                        address = [];

                    /* Anhand der uebergebene @- und .-Werte
                     * jedes Element des mailTo-Arrays pruefen
                     * und ggf. Zeichen ersetzen.
                     * mailTo-Elemente in address-Array speichern.
                     **/

                    for ( i=0; i<mailToLen; i++ )
                    {
                        address.push( point.test(mailTo[i]) ?
                            '.' :
                            at.test(mailTo[i]) ?
                                '@' :
                                mailTo[i] );
                    }

                    /* Inhalt des Elements mit mailto-Link
                     * und der unverschleieerten Adresse aus
                     * dem address-Array befuellen
                     **/

                    title = title === undefined || title === '' || title === null ? address.join('') : title;

                    this.innerHTML = '<a href="mailto:' + address.join('') + '">' + title + '</a>';
                }
            ) || this;
        };
    })(jQuery);
    let mailTitle = $('.crypt-mail').data('title');
    jQuery('.crypt-mail').cryptMail('(at)','(dot)', true, mailTitle);


    const homeOffice = new HomeOffice(moment());
});
